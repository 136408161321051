<template>
	<div class="soft__bottom--half push__bottom">
    <div class="wysiwyg push__bottom--half">
		<h2 class="soft__bottom" v-if="title">{{title}}</h2></div>
		<ul :class="getClass">	
			<li v-bind:key="item" v-for="item in items">{{item}}</li>
		</ul>
</div>
</template>

<script>
export default {
	name: 'Checklist',
	props: {
		title: String,
		variant: {
			type: String,
			default: "list-success"
		},
    items: [Object, Array]
	},
	data(){
		return {
			getClass : this.variant === null ? null : "list-" + this.variant,
		}
	}
}
</script>
<template>
	<div class="govuk-form-group" :class="{ 'form-group--error' : validate.$error}">
		<fieldset class="govuk-fieldset" :name="fieldsetName" :aria-describedby="getId">					
			<div>
				<legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
					<h1 class="govuk-fieldset__heading">{{title}}</h1>
				</legend>
				<span :id="getId" class="govuk-hint" v-if="hintText != null" v-html="hintText">
				</span>
				<div class="wysiwyg" v-if="hintTextHtml != []">
					<ul>
						<li v-for="item in hintTextHtml" :key="item">
							{{item}}
						</li>
					</ul>
				</div>
			</div>					
			<div v-if="detailsTitle">
				<Details :id="detailsId" :title="detailsTitle" :message="detailsMessage"/>
			</div>				
			<div>
				<span v-if="validate.$error" :id="getErrorId" class="error-message">
						{{errorMessage}}
				</span>					
				<slot></slot>
			</div>
		</fieldset>
	</div>
</template>

<script>
export default {
	name: 'RadioList',
	props: {
		id: String,
		fieldsetName: String,
		title: String,
		hintText: String,
		hintTextHtml: Array,
    detailsTitle: String,
    detailsMessage: String,
		error: Boolean,
		errorMessage: String,
		validate: Object
	},
	data() {
		return {
			getError: this.error ? "form-group--error" : null,
			getErrorId: this.id + "-error",
			getId: this.id + "-hint",
			getQuestion: this.id
		}
	}
}
</script>
<template>
	<div role="note" aria-label="Information" class="inset-text">
			{{content}}
	</div>
</template>

<script>
export default {
	name: 'InsetText',
	props: {
		content: String
	},
}
</script>
<template>
  <button class="govuk-button" type="submit" role="button" @click="$emit('submit')">{{text}}</button>
</template>

<script>
export default {
	name: 'Submit',
	props: {
		text: String,
	}
}
</script>
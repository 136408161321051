<template>
    <div class="govuk-form-group">
        <label class="govuk-label" :for="id">
            {{label}}
        </label>
        <select class="govuk-select" :id="id" :name="name" v-model="select">
            <slot></slot>
        </select>
    </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    id: String,
    name: String,
    label: String,
    value: Number,
  },
  computed: {
    select: {
      get: function () {
        return this.label;
      },
      set: function () {
        this.$emit("change", this.value);
      },
    },
  },
};
</script>
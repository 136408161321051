<template>
    <div class="grid-wrap">
        <div class="grid-row">
            <article class="push__bottom">
                <h1 v-if="!hideTitle">{{ title }}</h1>
                <p v-html="content"></p>
            </article>
        </div>
        <div class="button__container mt--48">
            <a @click="$router.push(neutralButtondUrl)" v-if="neutralButtondUrl !== ''"
                class="govuk-button govuk-button--neutral" :class="{ 'mr--16': !inApp }" role="button">{{ neutralButton
                }}</a>
            <a @click="$router.push(primaryButtonUrl)" class="govuk-button govuk-button" :class="{ 'mr--16': !inApp }"
                role="button">{{ primaryButton }}</a>
        </div>
    </div>
</template>

<script>
import { dataService, navigationService } from "../shared";

export default {
    name: 'NavigationStep',

    created() {
        this.loadQuestion();
        navigationService.addRoutingPath(this.name)
    },
    mounted() {
        this.resetAnswersOtherThanPrevious();
    },
    data() {
        return {
            id: "",
            name: "",
            sectionId: null,
            stepType: null,
            title: "",
            content: "",
            nextUrl: "",
            hideTitle: false,
            resetAnswers: false,
            neutralButton: "Cancel",
            neutralButtondUrl: "",
            primaryButton: "Continue",
            primaryButtonUrl: ""
        };
    },

    methods: {
        loadQuestion() {
            let json = dataService.getQuestions();
            let steps = json.steps;
            let currentStep = dataService.findElement(steps, dataService.parseUrl());

            this.id = currentStep.id;
            this.name = currentStep.responseId;
            this.title = currentStep.title;
            this.content = currentStep.description;
            this.hideTitle = currentStep.hideTitle;
            this.resetAnswers = currentStep.resetAnswers

            this.primaryButton = currentStep.responses[0].label;
            this.primaryButtonUrl = currentStep.responses[0].path;

            if (currentStep.responses.length === 2) {
                this.neutralButton = currentStep.responses[1].label;
                this.neutralButtondUrl = currentStep.responses[1].path;
            }
        },

        redirectToTimeout() {
            dataService.resetResponses();
            this.$router.push({ name: 'timeout' });
        },

        resetAnswersOtherThanPrevious() {
            if (this.resetAnswers) {
                let pagesNaviagationArray = navigationService.getpagesRoutingArray()
                dataService.resetResponsesExpectFromSteps(pagesNaviagationArray)
            }
        },
    }
}
</script>

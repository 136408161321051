let pagesRoutingArray = [];

const addRoutingPath = function (path) {
    let index = pagesRoutingArray.findIndex(s => s === path)
    if (index === -1) {
        pagesRoutingArray.push(path);
    } else {
        pagesRoutingArray.splice(index + 1);
    }
};

const getPreviousStep = function (currentStep) {
    let index = pagesRoutingArray.findIndex(s => s === currentStep);
    if (index !== -1 && index !== 0) {
        return pagesRoutingArray[index - 1];
    }

    return '';
};

const setNaviagtionArray = function (currentStep) {
    addRoutingPath(currentStep);
    return getPreviousStep(currentStep);
};

const getpagesRoutingArray = function () {
    return pagesRoutingArray;
};

export const navigationService = {
    addRoutingPath,
    getPreviousStep,
    setNaviagtionArray,
    getpagesRoutingArray
};

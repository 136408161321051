<template>
  <div class="grid-wrap">
    <div class="grid-row">
      <article class="push__bottom">
        <div class="grid-col grid-col--soft grid-xsmall-12 grid-large-9">
          <!-- <Section :section="this.sectionId" /> -->
        </div>
        <h1 v-if="title && titleVisible">{{ title }}</h1>
        <p v-html="content"></p>

        <div v-if="topSection != null && topSection.length != 0" class="accordion govuk-accordion__section">
          <div class="accordion__header js-enabled govuk-accordion__section-header">
            <button @click="toggleTopAccordion()" class="govuk-accordion__section-button"
              :aria-expanded="topSectionOpenOnLoad" :aria-controls="`collapse-top-section`">
              <p v-html="topSection.title" class="m-0"></p>
              <icon class="accordion__header--icon" v-show="!topSectionOpenOnLoad" icon="plus" />
              <icon class="accordion__header--icon" :class="topSectionOpenOnLoad ? 'accordion--active' : ''"
                v-show="topSectionOpenOnLoad" icon="minus" />
            </button>
          </div>
          <div v-show="topSectionOpenOnLoad" :id="`collapse-top-section`">
            <p v-html="topSection.body"></p>
          </div>
        </div>

        <Button class="govuk-button mt--24" text="Get started" :url="firstStep"></Button>

        <div v-if="bottomSection != null && bottomSection.length != 0" class="accordion govuk-accordion__section">
          <div class="accordion__header js-enabled govuk-accordion__section-header">
            <button @click="toggleBottomAccordion()" class="govuk-accordion__section-button"
              :aria-expanded="bottomSectionOpenOnLoad" :aria-controls="`collapse-bottom-section`">
              <p v-html="bottomSection.title" class="m-0"></p>
              <icon class="accordion__header--icon" v-show="!bottomSectionOpenOnLoad" icon="plus" />
              <icon class="accordion__header--icon" :class="bottomSectionOpenOnLoad ? 'accordion--active' : ''"
                v-show="bottomSectionOpenOnLoad" icon="minus" />
            </button>
          </div>
          <div v-show="bottomSectionOpenOnLoad" :aria-expanded="bottomSectionOpenOnLoad" :id="`collapse-bottom-section`">
            <p v-html="bottomSection.body"></p>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { dataService, navigationService } from "../shared";

export default {
  name: "Intro",
  data() {
    return {
      id: "",
      sectionId: null,
      title: "",
      titleVisible: true,
      content: "",
      topSection: null,
      bottomSection: null,
      topSectionOpenOnLoad: false,
      bottomSectionOpenOnLoad: false,
      error: false,
      errorMessage: "All fields are required",
      name: "",
      testMode: null,
      firstStep: "",
    };
  },
  created() {
    this.loadQuestion();
    navigationService.addRoutingPath(this.id)
  },
  methods: {
    toggleTopAccordion() {
      this.topSectionOpenOnLoad = !this.topSectionOpenOnLoad;
    },
    toggleBottomAccordion() {
      this.bottomSectionOpenOnLoad = !this.bottomSectionOpenOnLoad;
    },
    loadQuestion() {
      let json = dataService.getQuestions();
      let steps = json.steps;
      let currentStep = dataService.findElement(steps, dataService.parseUrl());
      let stepPosition = steps.indexOf(currentStep);
      let topContentSections = currentStep.content.topContentSections;
      let bottomContentSections = currentStep.content.bottomContentSections;

      this.id = currentStep.responseId;
      this.sectionId = currentStep.sectionId;
      this.title = currentStep.title;
      this.content = currentStep.content.body;
      this.titleVisible = currentStep.titleVisible;
      topContentSections.forEach((element) => {
        let topContent = {};
        topContent.title = element.title;
        topContent.body = element.body;
        topContent.openOnLoad = element.openOnLoad;
        this.topSectionOpenOnLoad = element.openOnLoad;
        this.topSection = topContent;
      });
      bottomContentSections.forEach((element) => {
        let bottomContent = {};
        bottomContent.title = element.title;
        bottomContent.body = element.body;
        bottomContent.openOnLoad = element.openOnLoad;
        this.bottomSectionOpenOnLoad = element.openOnLoad;
        this.bottomSection = bottomContent;
      });

      this.name = currentStep.responseId;
      this.errorMessage = currentStep.errorMessage;

      currentStep.responses.forEach((element) => {
        this.firstStep = element.path;
      });

      if (stepPosition === 0) {
        dataService.resetResponses();
        for (let s in steps) {
          let step = steps[s];
          if (step.questions.length !== 0) {
            step.questions.forEach((q) => {
              q.response = [];
            });
          }
        }
      }
    },
    redirectToTimeout() {
      dataService.resetResponses();
      this.$router.push({ name: "timeout" });
    },
  },
};
</script>
<template>
	<div class="push__bottom">
		<fieldset class="govuk-fieldset" :aria-describedby="hintId" role="group">
			<legend class="govuk-fieldset__legend govuk-fieldset__legend--l">    
				<h1 class="govuk-fieldset__heading">{{title}}</h1>    
			</legend>		
			<div v-if="detailsTitle" class="push__top">
				<Details :id="detailsId" :title="detailsTitle" :message="detailsMessage"/>
			</div>
			<slot></slot>
		</fieldset>   
	</div>
</template>

<script>
export default {
	name: 'Fieldset',
	props: {
		id: String,
		title: String,
		detailsTitle: String,
		detailsMessage: String
	},
    computed: {
        detailsId() {
            return `${this.id}-details`;
        },
    },
	data() {
		return {
			hintId : this.id + '-hint',
			currentYear : new Date().getFullYear()
		}
	}
}
</script>
<template>
    <div class="modal-backdrop">
        <div class="modal-overlay">
            <section class="modal-body">
                <p>{{ message }}</p>
            </section>
            <div class="modal-buttons">
                <button type="button" class="govuk-button govuk-button--neutral" @click="onCancel">Cancel</button>
                <button type="button" class="govuk-button" @click="onConfirm">Proceed</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            message: 'You have not provided an answer to all of the questions. Are you sure you want to proceed?',
        }
    },
    methods: {
        onConfirm() {
            this.$emit('confirm', true);
        },
        onCancel() {
            this.$emit('confirm', false);
        },
    },
}
</script>


<template>
  <a @click="$router.back(-1)" v-if="!checkLink" class="govuk-button govuk-button--neutral" :class="{ 'mr--16': !inApp }"
    role="button">Previous</a>
</template>

<script>
export default {
  name: "BackButton",
  data() {
    return {
      prevRoute: "",
      checkLink: window.location.href.indexOf("Intro") > -1,
      inApp: false,
    };
  },
  mounted() {
    let nativeApp = document.getElementById("isnativeapp");
    if (nativeApp) {
      this.inApp = true;
    }
  },
};
</script>
<template>
  <div class="push__bottom">
			<details class="govuk-details">
				<summary @click='toggle()' class="govuk-details__summary" :class="activeClass" role="button" type="button" tabindex="0" :aria-expanded="isOpen">
					<span class="govuk-details__summary-text">
						{{title}} 
					</span>
				</summary>
				<div class="govuk-details__text" :class="activeClass" tabindex="0" :aria-hidden="!isOpen" v-html="message">
				</div>				
			</details>
	</div>
</template>

<script>
export default {
	name: 'Details',
	props: {
		id: String,
		title: String,
		message: String,
	},
	data() {
		return {
			isOpen: false,
			activeClass: "",
		};
	},
	methods: {
		toggle: function () {
			this.isOpen = !this.isOpen;
			this.activeClass = this.isOpen ? "is--active" : ""
		}
	}
}
</script>
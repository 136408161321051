<template>
	<div class="govuk-radios">
		<div class="govuk-radios__item">
			<input class="govuk-radios__input" :id="id" :name="name" type="radio" :value="value" v-model="radio">
			<label class="govuk-label govuk-radios__label" :for="id">
					{{label}}
			</label>
		</div>
	</div>
</template>

<script>
export default {
  name: "RadioButton",
  props: {
    id: String,
    name: String,
    label: String,
    value: Number,
  },
  computed: {
    radio: {
      get: function () {
        return this.label;
      },
      set: function () {
        this.$emit("change", this.value);
      },
    },
  },
};
</script>
<template>
	<form class="grid-col grid-col--soft grid-xsmall-12  grid-large-9 push__ends" :id="formId" autocomplete="off"
		@submit.prevent="submit">
		<div v-if="error">
			<div role="alert" class="error-summary" data-module="error-summary" tabindex="-1"
				aria-labelledby="error-summary-1">
				<h2 id="error-summary-1" class="gamma push__bottom--half">There is a problem</h2>
				<ul class="error-summary__list">
					<li class="error-summary__item"><a href="#" @click="findElement" class="error-summary__link">{{
						formErrorMessage }}</a></li>
					<li class="error-summary__item" v-if="inputWrong"><a href="#" @click="findElement"
							class="error-summary__link">{{ inputWrongText }}</a></li>
				</ul>
			</div>
		</div>
		<slot></slot>

		<div class="button__container mt--48">
			<BackButton :class="{ d_none: firstStep }" v-if="previousUrl === ''" />
			<a @click="$router.push(previousUrl)" v-else class="govuk-button govuk-button--neutral"
				:class="{ 'mr--16': !inApp }" role="button">Previous</a>
			<!-- <Button class="govuk-button" text="Next step" :url="nextUrl"></Button> -->
			<Submit :text="submitButtonText" v-if="submit != null" :url="nextUrl" @click="submit" />
		</div>

	</form>
</template>

<script>
export default {
	props: {
		formId: String,
		firstStep: Boolean,
		error: {
			type: Boolean
		},
		formErrorMessage: {
			type: String,
			default: "Form has found errors."
		},
		submitButtonText: {
			type: String,
			default: "Next"
		},
		nextUrl: String,
		previousUrl: String
	},
	computed: {
		detailsId() {
			return `${this.formId}-details`;
		},
	},
	data() {
		return {
			inputWrong: false,
			inputWrongText: "",
		}
	},
	mounted() {
		this.submitButtonText = "Next";
	},
	methods: {
		submit() {
			this.$emit("submit");
		},

		forEachElement(selector, fn) {
			var elements = document.querySelectorAll(selector);
			for (var i = 0; i < elements.length; i++)
				fn(elements[i], i);
		},
		hasClass(el, className) {
			if (el.classList)
				el.classList.contains(className);
			else
				new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
		},
		findElement(e) {
			e.preventDefault();
			this.forEachElement(".form-group--error", (el) => {
				var errorTarget = el.querySelectorAll('input, textarea');
				if (errorTarget[0].type === "radio" || errorTarget[0].type === "checkbox") {
					document.getElementById(errorTarget[0].id).focus();
					document.getElementById(errorTarget[0].id).scrollIntoView();
				} else if (errorTarget[0].type == "number") {
					for (var i = 0; i < errorTarget.length; i++) {
						if (errorTarget[i].classList.contains("input--error")) {
							document.getElementById(errorTarget[i].id).focus();
							document.getElementById(errorTarget[i].id).scrollIntoView();
							break;
						}
					}
				} else if (errorTarget[0].type == "text" || errorTarget[0].type == "email") {
					for (var x = 0; x < errorTarget.length; i++) {
						if (errorTarget[x].classList.contains("input--error")) {
							document.getElementById(errorTarget[x].id).focus();
							document.getElementById(errorTarget[x].id).scrollIntoView();
							x = x - 1;
						}
					}
				}
			});
		}
	}
};
</script>

export default {
    generateHeaderForPdf(pdfData) {
        let _html = [];
        _html.push("<html>");
        _html.push(this.getHtmlHead());
        _html.push("<body class=\"quris-doc-page qrs-default-theme\">");
        _html.push("<img src=\" data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAAyCAMAAABI8M+tAAABgFBMVEX////j5uf8/Pzt7/D/lAH5+vrq7O4jMT3//v729/j+/"
        + "f3Z3N98ho7w8fLo6ux1gozS1tqSm6Lc4OKao6rO09YhMDzcH4N1hZHm6OnDyMxxf4rKz9PW2txndoEgMD5WYWqHlJ2AjJXg4uVOWWODj5hhbXaVn6by8/TGy8+0u8BlcXttfIegqK6stLltdn6OmJ/39vpdaHG"
        + "+xMlqeYT09fansLYmMzywt7z4jwEfLTl7iZS4v8V0fYV6Ucb/ulr/x3mirLP38Pn/pSf/nRI/S1bOfQzwiwCUYx3eL4svNjk6OzYfLz1KQjKMk5rjhQWGXSF8VMe6v8NIU13/rz6mrLFCPzT/1p28wcb/9ef/"
        + "4bbZgQiWdtLnYqj/+vL/6sz/8dxTRy96WCSScdDsgbn/0I5rUSilaxjvmcb63Oz3yOHi2fPjSJo2Qk20chPs5veDXcrCeBDZze+cftVeSyyLZ824ouGojdrJuej0utnyr9OPbM94TsWov/+a/+f/5vzj/"
        + "PBwXdyzAAAL4klEQVRo3uyZiVfaWBvGLwmJCdkXSUhYkrCFRSgQXAArKi51q1ur1W7WarX62X2mne+f/24CArZ0nG+Ozsw54z0ecpJcrje/+77v89wAwF27a3ftrt21f3kLPNvd3X0WuANx2XafTN3fmpmZ2bo/"
        + "9WT3Dgdsz6dm/P75tbm5ubV5v39m6jnxr4+RqVn/2vHRytKDBw+WVo6O9/yzUy/+hnlMX1zc+4cgebLl33u4NLQcHR6CLbo8tHQw55958leHytsv+58/73+5+CdU1sez8wtLbR6dNhxdWpiffY38pdP49fMkbOvrrz78/"
        + "Uhez64dDUWHrrbloU97/qkrEhTO5/OSndm5pXn8+m59/d3+K/j5+e0f/ArmG7hqpOrOG/Fhf3ouj2f3Xn5PxEuhlT3/6/6OdiPeaozRq7eD5OK39fVf3t6b/vBxffJjf1FBVFHEYRrbevucD3VvWQoO7"
        + "+Pw2Um8L9HDtTT8ZBSxc85aHToEGxad66dCPJ9d+zQICYTycm32yZXOQgEA2gyFSOCENvCbZfIFIvFQXOyvv+vPHrXCcbkUCjL19nl5BGCdZ7RycBKs4gNANsneN3gtTTgAzXWY6JzW9AI"
        + "+LeW43Mj1U3m2NX7QjyTadxI9mt+64lSKBQLQrWQrT9KlQla9SSTIx8l3nZT5dX3yS98dX4RKhzkDYXGQlnmfiLNYPuNDVF1sM1EjFnxuzQGs7gMBn265THYkUc3Zss9bSanOuRAJSaMclkUsXQVpi/cBSx+8rq/"
        + "9x6P9wfHyU9/p6IJ/6gcmDVLI6i1he3PkJplMv5p8Nd2Rn3eTvwT6mcAn45NWvuoYCS6Sr0pWSavyZiIituMkYvvUjEZStKHxsmlWVF7DlaxARWq1CAUHqGe5uuO4HWV3vJBZ01A7q42FaIMbtK67M2sr/WGytDf+sAcl"
        + "+mhv5sX3TFbBSDLUWs0X+Rtmsn/v50zQCC81eY2Uc0xVIDkbMKqVC/k8JsmIpmVzacMIJ1IMK1b4sOZIEoHmeJIeg3WlmI2ozQwsMxEvLFA2HBHLHINrTT6XGVRg/cf9Ejz84L2/"
        + "H8rwwpUyK1wywQtZk2NuDshJwGOCMK6OfPg+dyATKqJKTV9lLFUkqgLG6UAVEiW7zSQio2hdQzVFMOpWMZH0mOS9emJAJhkFT3EVGwAx6UYNoAwzKZZNyDIhGPoAHb4/36855+fDj"
        + "+b6oURX5ree9RV0OIQcBpZNqHlBvilLd3J4tvjGZTK9Y1R9BHj72+cPV5hYATUhEKk8ljCrKHCZ2CCVQrlOnLTrCWNWA5hj5HGuHSeQCeUxaSoko8UhE1IxGMKxaiNqRSzXAkwuRJA/"
        + "yjXxYmbuQS8oNmW7tXwVyuj72ee37I9Oz4LB4FOXCZpSlEQGBxcX/"
        + "bjVbM3UBBRITTxrCtV0NRUo5pqSlsoWRc1lknOZcKQdMRIhiUslJZ5zMlmB50RQhEzESi2hCBXYicrlDCVv1IRktZxAwBj8AjXA1PsXevZ1IomQsYnoSj+U6EP/"
        + "425vh2EwQKQ7PgntFe1A2r3T3xyvU7fv70UJRLL49NTLHd1QFMUIXbUQpGzrFhzHp4YMu1ypsxbAyzxjyyLvUPC/"
        + "YmEowwyFALGsO0yIF6k0hZC6zVAOsFgXajmEIpQ7KGpn9DQa4ikRFQmA8GUeG6Q6B8vdWjJRwiCT4WUXysGlnT0a7yoPQsdjWR0tdDxTKuFlp1tp8WQsVij3jcsUNtpLrF/rog+DwbNTrFNj1RETUknJgx1oPgElRb/"
        + "lsAVT412/dt5qNRCy0Gqde5FyMNrxbfP3u066ZPJ0Cd2B+hUABCjSAbh6Rc599s1tObUJ6WBuXBAYwHYsgGDA2YDrhMFEQMDPXlSdLAYXT+FoHd0JiM2aotSa7KC+qmAa5Vvfg3VL7HBLV304QeAwQs/dSJk/irbVeP7"
        + "+5eNgyW2wE/elRFIqFE2fEGtk5XC8BVNWjcsgUCgi29mGDCg6mUKlsMgVNtKClRaStOisCgUhPXAGX4PBwytajPGSGyoD3VTAwQC4fSYddzIc7yqr2hoePfb7FzpM1vqYlBIxE4/b2/"
        + "F84zwstEagly2Uwi4TGNEJOhMfWS1ZSS6TZ2M7Qmx7G7JKxTKNrG8zm2plBs7gTTD4xj3e2/+vu8s5OTw8IW1YVqrdeCCw6wQO5X8IHoxx/qQsElPjl1I8mqXpPIJJNF0aHV0Y979fuhTj"
        + "+0SXSXKzkGZioYYArE1e4MBGjGznDowTJCustuhGaSMOtxlObIdPJkcgq0IehONyPAQKqYFT+NaJE/Dhy1sw/ebMQyQaimld9sCF391ZEQEgJ77b2CH1mqbVr1z5P+qJ/"
        + "6hbYydgjXViExMTHpJHbVbRT70ai5W2y3GZidurpXD+PFykXSarDa+e5PXiZri6uTE2xsZSVAiPbYQyQjwc11ezlFCyoD34CZPTxeBZ29MTyDdXlRe/unZcUXb6PFvASbOwSDkoHnDfBDgA"
        + "+grCQRAcJZA6T5Is4V0lHIf16IxFdkToo9xrmMOwCAhn0oiThr3Z643mY//DaJ8Wu7ozNPqwh2Ro+aBnZBEzA4qraINXG7FkK1xNAZvDtgV3KbOxEpQjxozFRkCoFKNZWg8lC3m2QamNeMlON3hQHLw9moYcDt1VDJw"
        + "+XfRU2Q1LsaZUiS4TlVKMioSU6URkDFAmR1MZiVANUaJzsljKNWUB864ypqHVWHcj4GmgatCmaiuJStOpJY2wwZmsRHPytW+mZ//TM/ITMYaND7tI5h51SR37e68L4OogToAM+DasTFxFSAD/"
        + "MHePTjhpz5AgqhvmaRUDJAJQlCDgAVPTwD2SPymP3yCJp19Pvh7CY/DsW7uXz1SagR4TvWLpWd9IDc/kcGhlJVOPsJkEKrOGxFTKaZtjau5VNRJSc3V3Y+PJVqpoJfJ1ji1HUKmI45W6L8OJ+dp1abS7tdZ7/"
        + "KHRUmz0OyRLezM//q7Bx0px6aacPXLoJsz/2jXbn7ShKIzfviFtKaW0BFsLBVpebSEUsEYIEpwycGiABZCoaObADyPZ//9pt+BiQROXsMRE+3whuQkN/"
        + "Dj35DzP4euSyPzJGQMhHLaAgwlLMPsJowBoSS4hgC4hYSs28pqFHE9KATjEJuzTfboEp3nYyulljEFKUoytVDWAlDw6DxiJBlwu5osRbzcUZ3oS2qyS6M/DusN58IVV5SGjv50"
        + "+nd02WvISc5sH5PLjkUoklqhJPRwW1plgSyayBG2vHZZYERbLSAHuiQmyPE1AJhpkwkQa8DEYa6QZAjJJrJhA+5MqMEza+/blcRgeCKVzgZ86wgNod56vDpPMsX15/"
        + "f1IfuvEwAse59fX13PYSGhNM2GrxIxwmGM2mJSWTPYxnTVYAyDFFKiWqizrCWumyJK8BE89kRUTYCZjFa5ilSq6UI1DJpgVMWRJBkJEr2TfZELV12O2dmvhQGKXybMtDn7JoEYQ7PYElAomRBoBBI0FoKnoyahX7gW3oE"
        + "Kh1NOVOUoZlQJ8eW6FabPsF1HSZIICwEyinOVF2NeqQZC2GhmDkfkRIqLLU9LEwN7yY9A6V0EIkzf8iQx8AIlVGrsiBrwC36D/"
        + "Yf93MlkLlQ4O1hImZyBbrvV9eyBQzCs8U1TyqWRaLO4ptKgWi7Kl5BVxm2JZ3RkLDva24uK7Lq0f8EU79GpGvdNe4MfOSktna31RUyxNDaoc6lFGPg1RhRxH+DGllk3mt/8exB4fPzrSGvT7bmZv6vis/"
        + "VpwHzpo4WsRtZ/HqCQXV3mORxQ4AXBFJQCZQBeEMmpeL1j/47clEZn2E+CdNR7gs85LKNH27HCwtjNmkklfXxRVrWakbSbBX3kKUeXvarwmp5RuXgAfR9MB/vtuYxEYCn1b4IONiG1XL2QoKtPV/"
        + "YQdGKAmDco9Bh11jTJpdbPlD8QEjOv4RavjoBINdS4v8PoUfGLdDJv4pHXX3omen59Hd9p3rQnefLgBn1vT4yZ+Nlm0Lu/vW7PTM7x5PAWupsN68wS3ddKsD6fA6yKxZ9rx7dVwOLy6Hbv/8nsxUrol4sqVK1euPp7"
        + "+ALGz2awhBnAxAAAAAElFTkSuQmCC\" alt=\"DHI and RDS logo\" />");
        _html.push("<div class=\"header__content\">" + pdfData.header + "</div>");

        _html.push("</body>");
        _html.push("</html>");

        let html = _html.join("");
        return html;
    },
    generateFooterForPdf(pdfData) {
        let _html = [];
        _html.push("<html>");
        _html.push(this.getHtmlHead());
        _html.push("<body class=\"quris-doc-page qrs-default-theme\">");

        _html.push("<div class=\"footer\">");
        _html.push("<div class=\"footer__content\">" + pdfData.footer + "</div>");
        _html.push("</div>");

        _html.push("</body>");
        _html.push("</html>");

        let html = _html.join("");
        return html;
    },
    async generateHtmlForPdf(pdfData, qabpData, dataFormFields, content, date) {
        let _html = [];
        _html.push("<html>");
        _html.push(this.getHtmlHead());
        _html.push("<body class=\"quris-doc-page qrs-default-theme\">");
        _html.push("<div class\"qrs-typography\">");
        _html.push("<div class=\"header\">");
        _html.push("<img class=\"header__logo\" src=\"" + pdfData.logoUrl + "\" />");
        _html.push("<h1>Question and answer pathway name:" + " " + pdfData.title + "</h1>");
        _html.push("<p>" + date + "</p>");
        _html.push("<p><em>This QABP (decision tree) is provided by the Right Decision Service.</em></p>");
        _html.push("</div>");
        _html.push("<main>");
        _html.push("<section>");
        _html.push("<h2>Data form</h2>");
        _html.push("<div class=\"data-form-fields\">");
        dataFormFields.forEach((field) => {
            _html.push("<label><strong>" + field.name + "</strong></label>");
            if (field.response.length > 0) {
                _html.push("<p>" + field.response + "</p>");
            } else {
                _html.push("<p>" + "No answer provided" + "</p>");
            }
            
        });
        _html.push("</div>");
        _html.push("<h2>Outcome from question and answer tool</h2>");
        _html.push("<div>" + content.body + "</div>");

        _html.push("<h2>Responses to questions</h2>");
        qabpData.stepsAndQuestions.forEach((element) => {
            if (element.hasResponse) {
                _html.push("<div id=\"" + element.id + "\">");
                _html.push("<h3>" + element.id + "</h3>");

                element.questions.forEach((question) => {
                    if (!question.res.length || question.data.length === 0) {
                        if (!question.conditional) {
                            _html.push("<h4>" + question.label + "</h4>");
                            _html.push("<ul><li>No answer provided</li></ul>");
                        }
                        if (question.conditional && question.parentTriggered) {
                            _html.push("<h4>" + question.label + "</h4>");
                            _html.push("<ul><li>No answer provided</li></ul>");
                        }

                    } else {
                        question.res.forEach((response) => {
                            response.forEach((answer) => {
                                _html.push("<h4>" + question.label + "</h4>");
                                _html.push("<ul><li>" + answer.response + "</li></ul>");
                            });
                        });
                    }
                });
                _html.push("</div>");
                _html.push("<hr />");
            }
        });

        _html.push("</section>");
        _html.push("</main>");
        _html.push("</div>");

        _html.push("</body>");
        _html.push("</html>");

        let html = _html.join("");
        return html;
    },
    arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    },
    getHtmlHead() {
        let _html = [];
        _html.push("<head>");
        _html.push("<style>");
        _html.push("body { font-family:sofia-pro-soft, sans-serif; font-weight:400; font-style:normal;}");
        _html.push("main {padding:24px;}");
        _html.push(".header {position:relative; padding: 16px 24px 16px 16px;}");
        _html.push(".header__content {}");
        _html.push(".header__logo {}");
        _html.push(".footer {position:relative; padding: 16px 24px 16px 16px;}");
        _html.push(".footer__content {}");
        _html.push(".data-form-fields {padding: 16px 24px 16px 16px; background-color: #eee; margin-bottom: 48px;}");
        _html.push(".data-form-fields {padding: 16px 24px 16px 16px; margin-bottom: 48px;}");
        _html.push("</style>");
        _html.push("<link href=\"https://tac-int-quris-dev.azurewebsites.net/dist/css/site.min.css\" rel=\"stylesheet\">");
        _html.push("<link href=\"https://tac-int-quris-dev.azurewebsites.net/css/RTEStyles.css\" rel=\"stylesheet\">");
        _html.push("<link href=\"https://tac-int-quris-dev.azurewebsites.net/css/RTEStylesClient.css\" rel=\"stylesheet\">");
        _html.push("</head>");

        let html = _html.join("");
        return html;
    }
}
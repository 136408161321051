<template>
    <div class="govuk-date-input" id="passport-issued">
        <div class="govuk-date-input__item">
            <div class="govuk-form-group">
                <label class="govuk-label govuk-date-input__label" :for="'dayinput-' + item.questionId">
                    Day
                </label>
                <input class="govuk-input govuk-date-input__input govuk-input--width-2" :id="'dayinput-' + item.questionId"
                    name="date-of-birth-day" type="text" inputmode="numeric" v-model="dateOfBirth.day"
                    @change="dateInputChange(item, itemIdx)">
            </div>
        </div>
        <div class="govuk-date-input__item">
            <div class="govuk-form-group">
                <label class="govuk-label govuk-date-input__label" :for="'monthinput-' + item.questionId">
                    Month
                </label>
                <input class="govuk-input govuk-date-input__input govuk-input--width-2"
                    :id="'monthinput-' + item.questionId" name="date-of-birth-month" type="text" inputmode="numeric"
                    v-model="dateOfBirth.month" @change="dateInputChange(item, itemIdx)">
            </div>
        </div>
        <div class="govuk-date-input__item">
            <div class="govuk-form-group">
                <label class="govuk-label govuk-date-input__label" :for="'yearinput-' + item.questionId">
                    Year
                </label>
                <input class="govuk-input govuk-date-input__input govuk-input--width-4" :id="'yearinput-' + item.questionId"
                    name="date-of-birth-Year" type="text" inputmode="numeric" v-model="dateOfBirth.year"
                    @change="dateInputChange(item, itemIdx)">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        date: null,
        item: null
    },
    watch: {
        date(newDate) {
            this.convertDateToInputs(newDate)
        }
    },
    data() {
        return {
            dateOfBirth: {
                day: null,
                month: null,
                year: null,
            },
        }
    },
    methods: {
        dateInputChange() {
            let newDate = new Date(this.dateOfBirth.year, this.dateOfBirth.month - 1, this.dateOfBirth.day);

            let validation = this.validationDate();

            if (validation.isValid) {
                this.$emit('change', {
                    date: newDate,
                    validation: validation
                })
            } else {
                this.$emit('change', {
                    date: null,
                    validation: validation
                })
            }
        },

        validationDate() {
            let currentDate = new Date(Date.now());
            let newDate = new Date(this.dateOfBirth.year, this.dateOfBirth.month - 1, this.dateOfBirth.day);

            let expextedMaxDay = this.getDaysNumberInMonth(this.dateOfBirth.month, this.dateOfBirth.year);

            let validationErrors = {
                isValid: true,
                errors: []
            }

            if (this.dateOfBirth.year < 1900 || this.dateOfBirth.year > currentDate.getFullYear()) {
                validationErrors.isValid = false;
                validationErrors.errors.push(`Year field must be within 1900 - ${currentDate.getFullYear()}`);
            }

            if (this.dateOfBirth.month > 12 || this.dateOfBirth.month < 0) {
                validationErrors.isValid = false;
                validationErrors.errors.push('Month field must be within 1 - 12');
            }

            if (this.dateOfBirth.day < 1 || this.dateOfBirth.day > expextedMaxDay) {
                validationErrors.isValid = false;
                validationErrors.errors.push(`Day field for this month must be within 1 - ${expextedMaxDay}`);
            }

            if (newDate.setHours(0, 0, 0, 0) >= currentDate.setHours(0, 0, 0, 0)) {
                validationErrors.isValid = false;
            }

            if (!validationErrors.isValid) {
                validationErrors.errors.unshift('This is not a valid date');
            }

            return validationErrors;
        },

        isLeap(year) {
            return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
        },

        getDaysNumberInMonth(month, year) {
            let normalMonths = ['4', '04', '6', '06', '9', '09', '11'];

            if (month === '2' || month === '02') {
                return this.isLeap(year) ? 29 : 28
            }
            else if (normalMonths.includes(month)) {
                return 30;
            }
            else {
                return 31;
            }
        },

        convertDateToInputs(date) {
            if (date) {
                this.dateOfBirth = {
                    day: date.getDate(),
                    month: date.getMonth() + 1,
                    year: date.getFullYear(),
                }

                let el = document.getElementById(`dayinput-${this.item.questionId}`);
                let event = new Event('change');
                el.dispatchEvent(event);
            }
        },

        checkIsDateInputNotNullOrNotEmpyt() {
            for (const key in this.dateOfBirth) {
                if (this.dateOfBirth[key] === null || this.dateOfBirth[key] === '') {
                    return false;
                }
            }

            return true;
        },
    },
}
</script>
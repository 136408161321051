import { dataService } from '../shared';

export default {
    isNotEligibleWitReason(answers) {
        if (answers.length === 1) {
            let reasons = [];
            switch (answers[0].totalScore) {
                case 1:
                    reasons.push('Currently receiving psychological therapy');
                    return {
                        isNotEligible: true,
                        isReceivingPsychologicalTherapy: true,
                        reasons: reasons
                    }
                case 2:
                    reasons.push('Aged less than 16');
                    return {
                        isNotEligible: true,
                        isReceivingPsychologicalTherapy: false,
                        reasons: reasons
                    }
                case 3:
                    reasons.push('Currently receiving psychological therapy');
                    reasons.push('Aged less than 16');
                    return {
                        isNotEligible: true,
                        isReceivingPsychologicalTherapy: true,
                        reasons: reasons
                    }
                default:
                    return {
                        isNotEligible: false,
                    }
            }
        }

        return {
            isNotEligible: false,
        }
    },

    questionScoringOverview(questions, responses) {
        let steps = dataService.getQuestions().steps;
        let response = [];

        for (let question of questions) {
            let step = dataService.findElementByResponseId(steps, question.questionId);
            let totalScore = responses.find(f => f.responseId === question.questionId).totalScore;

            let questionSummary = {
                title: question.title.replace('summary', 'scores'),
                totalScore: totalScore,
                questionsScores: []
            }

            for (let stepQuestion of step.questions) {
                let questionResponsesScores = {
                    question: stepQuestion.label,
                    score: stepQuestion.response.length === 0 ? 0 : stepQuestion.response.score,
                    text: stepQuestion.response.length === 0 ? 'Not answered' : stepQuestion.response.response.replace(/<p>/g, '').replace(/<\/p>/g, '')
                }

                questionSummary.questionsScores.push(questionResponsesScores);
            }

            response.push(questionSummary);
        }

        return response;
    },

    getAdditionalInfoSummary(question, responses) {
        let info = this.getInputFromSummary(question, responses);

        if (info.length === 0) {
            return '';
        }

        return info ? info : '';
    },

    getPatientInfoSummary(patientInfo, responses) {
        let name = this.getInputFromSummary(patientInfo.nameId, responses);
        let lastName = this.getInputFromSummary(patientInfo.lastNameId, responses);
        let dateOfBirth = this.parseStringToDate(this.getInputFromSummary(patientInfo.dateOfBirthId, responses));
        const options = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        };

        return {
            patientName: `${name} ${lastName}`,
            dateOfBirth: new Date(dateOfBirth).toLocaleString('en-GB', options),
            therapyStatus: patientInfo.therapyStatus,
            dateOfScreening: new Date().toLocaleString('en-GB', options)
        }
    },

    getInputFromSummary(id, responses) {
        for (let response of responses) {
            let item = response.responses.find(f => f.responseId === id)
            if (item && item.response.length === 1) {
                return item.response[0].response;
            }
        }
    },


    parseStringToDate(dateString) {
        const [day, month, year] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day);
    },

    calculateMultipleSummary(multipleScoring, responses) {
        let scoreResponses = [];

        for (let score of multipleScoring) {
            let response = {
                title: '',
                notAllQuestionsAnswered: false,
                notAllQuestionsAnsweredText: '',
                imageCategory: '',
                description: ''
            };
            response.title = score.title;

            let notAllQuestionsAnswered = this.NotAllQuestionsAnswered(score.questionsAnsweredIds, responses)

            if (notAllQuestionsAnswered) {
                response.notAllQuestionsAnswered = notAllQuestionsAnswered;
                response.notAllQuestionsAnsweredText = score.questionAnsweredLabel;
            }

            let category = this.GetScoringCategory(score.pdfSummaryScorings, responses);

            response.description = category.description;
            response.imageCategory = category.imageCategory;

            scoreResponses.push(response);
        }

        return scoreResponses;
    },

    NotAllQuestionsAnswered(answerIds, responses) {
        for (let answerId of answerIds) {
            let response = responses.find(s => s.id === answerId);
            if (response && !this.isResponseExist(response.responses)) {
                return true;
            }
        }

        return false;
    },

    GetScoringCategory(pdfSummaryScorings, questions) {

        let isScoreCorrect = false;
        let scoringRules = [];
        for (let score of pdfSummaryScorings) {

            for (let row of score.scoringRows) {
                isScoreCorrect = this.GetScoringCategoryFromStepOrQuestion(row, questions);
                scoringRules.push(isScoreCorrect)
            }

            let allScoringRulesMeet = scoringRules.filter(s => s === false).length === 0;
            scoringRules = [];

            if (allScoringRulesMeet) {
                return {
                    description: score.description.replace(/<div class="ewa-rteLine"><\/div>/g, '<br>'),
                    imageCategory: score.imageCategory
                };
            }
        }

        return {};
    },

    GetScoringCategoryFromStepOrQuestion(pdfSummaryScoringRow, questions) {
        if (pdfSummaryScoringRow.questionType !== 'qabStep') {
            let scoring = {
                questionId: pdfSummaryScoringRow.questionId,
                from: pdfSummaryScoringRow.from,
                to: pdfSummaryScoringRow.to
            };

            return this.GetRadioButtonAnswerForScoring(scoring, questions);
        }

        let question = questions.find(s => s.responseId === pdfSummaryScoringRow.questionId)

        if (question) {
            return pdfSummaryScoringRow.from <= question.totalScore && question.totalScore <= pdfSummaryScoringRow.to
        }

        return false;
    },

    calculateSingleSummary(singleScorings, responses) {
        let scoreResponses = [];
        for (let score of singleScorings) {
            let scoreResponse = {
                title: '',
                totalScoreLabel: '',
                totalScoreNumber: 0,
                description: '',
                additionalInfo: {
                    turnOn: false,
                    description: '',
                    turnOnRedFlag: false
                }
            }

            let response = responses.find(s => s.responseId === score.questionId)

            if (response) {
                scoreResponse.totalScoreLabel = score.label;
                scoreResponse.totalScoreNumber = response.totalScore;
                scoreResponse.title = score.title;
                for (let summary of score.pdfSummaryScorings) {
                    if (summary.from <= response.totalScore && response.totalScore <= summary.to) {
                        scoreResponse.description = summary.description;
                    }
                }
            }

            if (score.additionalScorings.length !== 0) {

                scoreResponse.additionalInfo.turnOn = true;

                for (let additionalScore of score.additionalScorings) {
                    let scoring = {
                        questionId: additionalScore.questionId,
                        from: additionalScore.from,
                        to: additionalScore.to
                    };

                    if (this.GetRadioButtonAnswerForScoring(scoring, responses)) {
                        scoreResponse.additionalInfo.description = additionalScore.description;
                        scoreResponse.additionalInfo.turnOnRedFlag = additionalScore.turnOnRedFlag;
                    }

                }
            }

            scoreResponses.push(scoreResponse);
        }

        return scoreResponses;
    },

    //When user choose to add more detail question (only radio button right now) this method allows to find correct questionId and check its scoring
    GetRadioButtonAnswerForScoring(scoring, questions) {
        for (let question of questions) {
            let questionResponse = question.responses.find(s => s.responseId === scoring.questionId)
            if (questionResponse) {
                let questionResponseScore = questionResponse.response.length === 0 ? 0 : questionResponse.response[0].score;

                if (scoring.from <= questionResponseScore && questionResponseScore <= scoring.to) {
                    return true;
                }
            }
        }

        return false;
    },

    isResponseExist(responses) {
        return !responses.find(s => s.response.length === 0);
    },

}

<template>
	<div class="govuk-form-group" :class="{ 'form-group--error' : error}">
		<fieldset class="govuk-fieldset" :name="fieldsetName" :aria-describedby="getId">
			<legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
				<h1 class="govuk-fieldset__heading">{{title}}</h1>
			</legend>
			<span :id="getId" class="govuk-hint" v-if="hintText != null">
				{{hintText}}
			</span>
			<Details v-if="detailsTitle" id="detailsId" :title="detailsTitle" :message="detailsMessage"/>
			<span v-if="error" :id="getErrorId" class="error-message">
				{{errorMessage}}
			</span>
			<slot name="content"></slot>
			<slot></slot>
		</fieldset>
	</div>
</template>

<script>
export default {
	name: 'CheckList',
	props: {
		id: String,
		fieldsetName: String,
		title: String,
		hintText: String,
    detailsTitle: String,
    detailsMessage: String,
		error: Boolean,
		errorMessage: String,
	},
	data() {
		return {
			getError: this.error ? "form-group--error" : null,
			getErrorId: this.id + "-error",
			getId: this.id + "-hint",
		}
	}
}
</script>
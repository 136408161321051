<template>
	<div class="govuk-checkboxes__item">
		<input class="govuk-checkboxes__input" :id="id" :name="name" type="checkbox" :value="value" v-model="checkbox">
		<label class="govuk-label govuk-checkboxes__label" :for="id">
				{{label}}
		</label>
	</div>
</template>

<script>
export default {
	name: 'CheckboxButton',
	props: {
		id: String,
		name: String,
		label: String,
		value: Number
	},
	computed: {
		checkbox: {
			get: function() {
				return this.label
			},
			set: function() {
				this.$emit("change", this.value)
			}
		}
	}
}
</script>
<template>
  <section :id="id" class="outcome">
    <!-- <BackLink /> -->
    <h1 id="stepTitle">{{ stepTitle }}</h1>

    <div v-if="pdfDocCreated" class="govuk-notification-banner govuk-notification-banner--success" role="alert"
      aria-labelledby="govuk-notification-banner-title" data-module="govuk-notification-banner">
      <div class="govuk-notification-banner__header">
        <h2 class="govuk-notification-banner__title" id="govuk-notification-banner-title">
          Success
        </h2>
      </div>
      <div class="govuk-notification-banner__content">
        <h3 class="govuk-notification-banner__heading">
          Responses have been saved as a PDF document
        </h3>
        <p class="govuk-body">
          Follow the link below to view and share your saved PDF <br />
          <a class="govuk-notification-banner__link" target="_blank" :href="pdfLink">My saved PDF document</a>
        </p>
      </div>
    </div>

    <!-- ERROR VALIDATION -->
    <div v-if="pdfDocCreationError" id="pdf-error-save" class="govuk-error-summary" aria-labelledby="error-summary-title"
      role="alert" tabindex="-1" data-module="govuk-error-summary">
      <h2 class="govuk-error-summary__title" id="error-summary-title">
        There is a problem
      </h2>
      <div class="govuk-error-summary__body">
        <ul class="govuk-list govuk-error-summary__list">
          <li>Unable to save to PDF</li>
        </ul>
      </div>
    </div>

    <div v-if="mandatoryQuestions.length > 0" id="form-field-validation" class="govuk-error-summary"
      aria-labelledby="error-summary-title" role="alert" tabindex="-1" data-module="govuk-error-summary">
      <h2 class="govuk-error-summary__title" id="error-summary-title">
        There is a problem
      </h2>
      <div class="govuk-error-summary__body">
        <ul class="govuk-list govuk-error-summary__list">
          <li v-for="(mandatoryQuestion, mqIdx) in mandatoryQuestions" v-bind:key="mqIdx">
            <a @click="
              goToErrorField(mandatoryQuestion.id, mandatoryQuestion.type)
            ">Please select an answer for "{{ mandatoryQuestion.name }}"</a>
          </li>
        </ul>
      </div>
    </div>

    <!-- END -->

    <div class="outcome__responses">
      <div id="bodyContent" v-html="bodyContent" class="mb--48"></div>

      <div class="accordion govuk-accordion__section">
        <div class="accordion__header js-enabled govuk-accordion__section-header">
          <button @click="toggleReviewAnswersAccordion()" class="govuk-accordion__section-button"
            :aria-expanded="reviewAnswersOpenOnLoad" :aria-controls="`collapse-bottom-section`">
            <p class="m-0">Review Answers</p>
            <icon class="accordion__header--icon" v-show="!reviewAnswersOpenOnLoad" icon="plus" />
            <icon class="accordion__header--icon" :class="reviewAnswersOpenOnLoad ? 'accordion--active' : ''"
              v-show="reviewAnswersOpenOnLoad" icon="minus" />
          </button>
        </div>
        <div v-show="reviewAnswersOpenOnLoad" :id="`collapse-bottom-section`">
          <div id="outcome-responses">
            <div v-for="(item, itemIdx) in data.stepsAndQuestions" v-bind:key="itemIdx">
              <div v-if="item.hasResponse">
                <h2>{{ item.id }}</h2>
                <div v-for="(inneritem, innerIdx) in item.questions" v-bind:key="innerIdx">
                  <div v-if="!inneritem.res.length || inneritem.data.length === 0">
                    <div v-if="!inneritem.conditional">
                      <h4>{{ inneritem.label }}</h4>
                      <ul>
                        <li>No answer provided</li>
                      </ul>
                    </div>

                    <div v-if="inneritem.conditional && inneritem.parentTriggered">
                      <h4>{{ inneritem.label }}</h4>
                      <ul>
                        <li>No answer provided</li>
                      </ul>
                    </div>
                  </div>

                  <div v-else>
                    <h4 v-if="inneritem.label.length > 0">
                      {{ inneritem.label }}
                    </h4>
                    <div v-for="(answerItem, answerIdx) in inneritem.res" v-bind:key="answerIdx">
                      <div v-for="(response, resIdx) in answerItem" v-bind:key="resIdx">
                        <ul>
                          <li v-html="response.response"></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="copyToClipboard">
        <a id="copy-to-clipboard__btn" class="govuk-button mt--16" text="Copy To Clipboard"
          @click="copyResponsesToClipboard()"><i class="fa fa-copy"></i> Copy Answers to Clipboard</a>
        <a id="copied__btn" class="govuk-button" text="Copied" style="display: none">Copied</a>
      </div>

      <div v-if="pdfDataCollectionForm != null" class="data-collection__form mt--48">
        <article>
          <form class="
                grid-col grid-col--soft
                grid-xsmall-12 grid-large-9
                push__ends
              ">
            <fieldset class="govuk-fieldset">
              <legend class="govuk-fieldset__legend govuk-fieldset__legend--m">
                <h2 class="govuk-fieldset__heading">{{ pdfFormTitle }}</h2>
              </legend>
              <div v-if="pdfFormDescription" v-html="pdfFormDescription" class="govuk-hint mb--48"></div>
              <div class="" v-for="(item, idx) in pdfDataCollectionForm.fields" v-bind:key="idx">
                <div v-if="item.type == 'text'" :id="item.id + '-id'" class="govuk-form-group">
                  <div class="govuk-label-wrapper">
                    <label class="govuk-label govuk-label--s" :for="item.id + '-id'">
                      {{ item.name }}
                      <span v-if="item.isMandatory" style="font-weight: bold;">*</span>
                    </label>
                  </div>
                  <div v-if="item.description" class="govuk-hint">
                    {{ item.description }}
                  </div>
                  <span :id="item.name + '-field-error__message'" class="field-error__message hide">Please select an
                    answer</span>
                  <input class="govuk-input" :id="item.id + '-id'" :name="item.id + '-id'" v-model="item.response"
                    type="text" />
                </div>
                <div v-if="item.type == 'textarea'" :id="item.id + '-id'" class="govuk-form-group">
                  <div class="govuk-label-wrapper">
                    <label class="govuk-label govuk-label--s" :for="item.id + '-id'">
                      {{ item.name }}
                      <span v-if="item.isMandatory" style="font-weight: bold;">*</span>
                    </label>
                  </div>
                  <div v-if="item.description" class="govuk-hint">
                    {{ item.description }}
                  </div>
                  <span :id="item.name + '-field-error__message'" class="field-error__message hide">Please select an
                    answer</span>
                  <textarea class="govuk-textarea" :id="item.id + '-id'" :name="item.id + '-id'"
                    v-model="item.response"></textarea>
                </div>
              </div>
            </fieldset>
          </form>
        </article>
      </div>

      <div class="button__container mt--48">
        <BackButton />
        <span v-if="saveAsPdf">
          <button v-if="!creatingPDF" class="govuk-button" data-module="govuk-button" text="Save as PDF"
            @click="submitForm()" data-prevent-double-click="true">
            Save as PDF
          </button>
          <button v-if="creatingPDF" class="govuk-button" data-module="govuk-button">
            <i class="fa fa-circle-o-notch fa-spin"></i> Saving
          </button>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { dataService } from "../shared";
import pdfHelper from "../shared/pdfHelper.js";

export default {
  name: "Outcome",

  data() {
    return {
      id: "",
      sectionId: null,
      stepType: null,
      title: "",
      stepTitle: "",
      outcomeTitle: "",
      bodyContent: "",
      topContentSection: "",
      bottomContentSection: "",
      copyToClipboard: false,
      saveAsPdf: false,
      pdfData: {
        logo: "",
        header: "",
        footer: "",
        title: "",
      },
      pdfFormTitle: "",
      pdfFormDescription: "",
      pdfDataCollectionForm: {},
      pdfDataFormFields: [],
      mandatoryQuestions: [],
      formIsValid: false,
      pdfLink: null,
      creatingPDF: false,
      pdfDocCreated: false,
      pdfDocCreationError: false,
      reviewAnswersOpenOnLoad: false,
      answers: null,
      steps: null,
      data: null,
      res: null,
    };
  },

  created() {
    this.loadQuestion();
  },
  mounted() {
    this.loadAnswers();
    this.getStepData();
  },
  computed: {},

  methods: {
    toggleReviewAnswersAccordion() {
      this.reviewAnswersOpenOnLoad = !this.reviewAnswersOpenOnLoad;
    },

    loadQuestion() {
      let json = dataService.getQuestions();
      let steps = json.steps;
      let currentStep = dataService.findElement(steps, dataService.parseUrl());

      this.id = currentStep.id;
      this.title = json.title;
      this.stepTitle = currentStep.title;
      this.outcomeTitle = currentStep.content.title;
      this.bodyContent = currentStep.content.body;
      this.topContentSection = currentStep.content.topContentSections;
      this.bottomContentSection = currentStep.content.bottomContentSections;
      this.copyToClipboard = currentStep.externalServices.copyToClipboard;
      this.saveAsPdf = currentStep.externalServices.saveAsPdf;
      this.pdfData.title = json.title;
      this.pdfData.header = currentStep.externalServices.pdfHeader;
      this.pdfData.footer = currentStep.externalServices.pdfFooter;
      this.pdfData.logo = currentStep.externalServices.pdfLogoUrl;
      this.pdfData.logoUrl = currentStep.externalServices.pdfLogoAbsoluteUrl;
      if (currentStep.externalServices.pdfDataForm != null) {
        this.pdfFormTitle = currentStep.externalServices.pdfDataForm.title;
        this.pdfFormDescription =
          currentStep.externalServices.pdfDataForm.description;
        this.pdfDataCollectionForm = currentStep.externalServices.pdfDataForm;
        this.pdfDataFormFields =
          currentStep.externalServices.pdfDataForm.fields;
      }

      this.steps = steps;
    },

    loadAnswers() {
      let responses = dataService.getCompleteData();
      this.answers = responses;
    },

    getStepData() {
      let stepsAndQuestions = [];
      let stepResponses = [];

      for (let e in this.answers) {
        let answer = this.answers[e];
        stepResponses.push(answer.responses);
      }

      for (let i in this.steps) {
        let item = this.steps[i];
        if (item.type === "DecisionStep") {
          let stepData = {
            id: item.id,
            hasResponse: true,
            questions: [],
          };
          for (let innerItem in item.questions) {
            let question = item.questions[innerItem];
            stepData.questions.push({
              id: question.questionId,
              label: question.label,
              data: question.response,
              conditional: question.isConditional,
              parentTriggered: false,
              res: [],
            });
          }
          stepsAndQuestions.push(stepData);
        }
      }

      stepsAndQuestions.forEach((e) => {
        let parentTrigger = "";

        for (let el in stepResponses) {
          let element = stepResponses[el];

          if (element.length >= 1) {
            for (let data in element) {
              let i = element[data];

              if (i.response.length > 0) {
                for (let r in i.response) {
                  let res = i.response[r];
                  parentTrigger = res.trigger;
                }
              }
              e.questions.forEach((e) => {
                if (i.responseId === e.id && i.response.length >= 0) {
                  e.res.push(i.response);
                  if (i.responseId === parentTrigger) {
                    e.parentTriggered = true;
                  }
                }
              });
            }
          }
        }

        if (e.questions.length >= 1) {
          for (let r in e.questions) {
            let itemRes = e.questions[r];
            if (itemRes.res.length == 0) {
              e.hasResponse = false;
            }
          }
        }
      });

      this.data = {
        stepsAndQuestions,
      };
    },

    goToErrorField(field) {
      let errorField = document.getElementById(field + "-id");
      let input = errorField.querySelector(
        "[id^=" + CSS.escape(field + "-id") + "]"
      );
      errorField.scrollIntoView({ behavior: "smooth" }, true);
      input.focus();
    },

    checkMandatoryQuestions() {
      let mandatoryQuestions = [];
      this.mandatoryQuestions = [];
      this.formIsValid = false;

      this.pdfDataFormFields.forEach((i) => {
        if (i.isMandatory && i.response.length === 0) {
          mandatoryQuestions.push({
            id: i.id,
            name: i.name,
            type: i.type,
          });
        } else if (i.isMandatory) {
          let questionField = document.getElementById(i.id + "-id");
          let fieldMessage = document.getElementById(
            i.name + "-field-error__message"
          );
          questionField.classList.remove("govuk-form-group--error");
          fieldMessage.classList.add("hide");
        }
      });

      if (mandatoryQuestions.length > 0) {
        for (let i in mandatoryQuestions) {
          let item = mandatoryQuestions[i];
          let questionErrorField = document.getElementById(item.id + "-id");
          let fieldErrorMessage = document.getElementById(
            item.name + "-field-error__message"
          );

          this.mandatoryQuestions.push({
            id: item.id,
            name: item.name,
            type: item.type,
          });
          questionErrorField.classList.add("govuk-form-group--error");
          fieldErrorMessage.classList.remove("hide");
        }
      }

      if (mandatoryQuestions.length == 0) {
        this.formIsValid = true;
        mandatoryQuestions = [];
        this.mandatoryQuestions = [];
      }
    },

    validateForm() {
      this.checkMandatoryQuestions();
      if (this.formIsValid) {
        this.saveResponsesAsPdf();
      }
    },

    async saveResponsesAsPdf() {
      this.creatingPDF = true;
      let date = new Date();
      let outcomeContent = {
        title: this.outcomeTitle,
        body: this.bodyContent,
        topSection: this.topContentSection,
        bottomSection: this.bottomContentSection,
      };

      let pdfHtml = await pdfHelper.generateHtmlForPdf(
        this.pdfData,
        this.data,
        this.pdfDataFormFields,
        outcomeContent,
        date
      );
      let pdfHeader = pdfHelper.generateHeaderForPdf(this.pdfData);
      let pdfFooter = pdfHelper.generateFooterForPdf(this.pdfData);

      this.pdfDocCreationError = false;

      var htmlBody = { Html: pdfHtml, Header: pdfHeader, Footer: pdfFooter };

      try {
        const response = await fetch(
          // "https://tac-dhi-rdsqa-ext-live-tst.azurewebsites.net/api/pdf",
          'https://tac-dhi-rdsqa-ext-live.azurewebsites.net/api/pdf',
          // 'https://localhost:44394/api/pdf',
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(htmlBody),
          }
        );

        const link = await response.json();
        // window.open(link, "_blank");
        this.pdfDocCreationError = false;
        this.creatingPDF = false;
        this.pdfDocCreated = true;
        this.pdfLink = link;
        this.scrollToTopOfQAB();
      } catch (err) {
        this.pdfDocCreationError = true;
        this.scrollToTopOfQAB();
      }
    },

    submitForm() {
      if (this.pdfDataCollectionForm != null) {
        this.validateForm();
      } else {
        this.saveResponsesAsPdf();
      }
    },

    copyResponsesToClipboard() {
      let copyToClipboardBtn = document.getElementById(
        "copy-to-clipboard__btn"
      );
      let copiedBtn = document.getElementById("copied__btn");
      let questionsAndAnswers = document.createElement("div");
      let currentDate = new Date();
      questionsAndAnswers.style.position = "absolute";
      questionsAndAnswers.style.left = "-9999px";
      questionsAndAnswers.style.opacity = "0";

      document.body.appendChild(questionsAndAnswers);

      let pathwayTitleTag = document.createElement("h1");
      let pathwayTitle = document.createTextNode(
        "Question and answer pathway name:" + " " + this.title
      );
      pathwayTitleTag.appendChild(pathwayTitle);
      questionsAndAnswers.appendChild(pathwayTitleTag);

      let dateStampTag = document.createElement("p");
      let dateStamp = document.createTextNode(currentDate);
      dateStampTag.appendChild(dateStamp);
      questionsAndAnswers.appendChild(dateStampTag);

      let rdsTextTag = document.createElement("p");
      let rdsText = document.createTextNode(
        "This QABP (decision tree) is provided by the Right Decision Service."
      );
      rdsTextTag.appendChild(rdsText);
      questionsAndAnswers.appendChild(rdsTextTag);
      let rdsTextlineBreak = document.createElement("p");
      questionsAndAnswers.appendChild(rdsTextlineBreak);

      let outcomeContentTitleTag = document.createElement("h2");
      let outcomeContentTitle = document.createTextNode(
        "Outcome from question and answer tool"
      );
      outcomeContentTitleTag.appendChild(outcomeContentTitle);
      questionsAndAnswers.appendChild(outcomeContentTitleTag);
      let outcomeTitlelineBreak = document.createElement("p");
      questionsAndAnswers.appendChild(outcomeTitlelineBreak);

      if (this.bodyContent.length > 0) {
        let outcomeContentContainer = document.createElement("p");
        let bodyContentValue = dataService.removeTags(this.bodyContent);
        let outcomeContent = document.createTextNode(bodyContentValue);
        outcomeContentContainer.appendChild(outcomeContent);
        questionsAndAnswers.appendChild(outcomeContentContainer);
      }

      let responsesTitleTag = document.createElement("h2");
      let responseTitle = document.createTextNode("Responses to questions");
      responsesTitleTag.appendChild(responseTitle);
      questionsAndAnswers.appendChild(responsesTitleTag);
      let responseTitlelineBreak = document.createElement("p");
      questionsAndAnswers.appendChild(responseTitlelineBreak);

      this.data.stepsAndQuestions.forEach((e) => {
        if (e.hasResponse) {
          let stepTitleTag = document.createElement("h3");
          let stepTitle = document.createTextNode(e.id);
          stepTitleTag.appendChild(stepTitle);
          questionsAndAnswers.appendChild(stepTitleTag);
          let stepTitlelineBreak = document.createElement("p");
          questionsAndAnswers.appendChild(stepTitlelineBreak);

          for (let i in e.questions) {
            let item = e.questions[i];
            let questionTitleTag = document.createElement("h4");
            let questionTitle;
            let answerListTag = document.createElement("ul");
            let answerTag = document.createElement("li");
            let answerTitle;
            let answerlineBreak = document.createElement("p");

            if (!item.res.length || item.data.length === 0) {
              if (item.label.length > 0 && !item.conditional) {
                questionTitle = document.createTextNode(item.label);
                questionTitleTag.appendChild(questionTitle);
                questionsAndAnswers.appendChild(questionTitleTag);

                answerTitle = document.createTextNode("• No Answer provided");
                answerTag.appendChild(answerTitle);
                answerListTag.appendChild(answerTag);
                questionsAndAnswers.appendChild(answerListTag);
                questionsAndAnswers.appendChild(answerlineBreak);
              }
              if (item.conditional && item.parentTriggered) {
                questionTitle = document.createTextNode(item.label);
                questionTitleTag.appendChild(questionTitle);
                questionsAndAnswers.appendChild(questionTitleTag);

                answerTitle = document.createTextNode("• No Answer provided");
                answerTag.appendChild(answerTitle);
                answerListTag.appendChild(answerTag);
                questionsAndAnswers.appendChild(answerListTag);
                questionsAndAnswers.appendChild(answerlineBreak);
              }
            } else {
              if (item.label.length > 0) {
                questionTitle = document.createTextNode(item.label);
                questionTitleTag.appendChild(questionTitle);
                questionsAndAnswers.appendChild(questionTitleTag);
              }
              for (let innerItem in item.res) {
                let inner = item.res[innerItem];
                for (let a in inner) {
                  let answer = inner[a];
                  let textValue = dataService.removeTags(answer.response);
                  answerTitle = document.createTextNode("•" + " " + textValue);
                  answerTag.appendChild(answerTitle);
                  answerListTag.appendChild(answerTag);
                  questionsAndAnswers.appendChild(answerListTag);
                  questionsAndAnswers.appendChild(answerlineBreak);
                }
              }
            }
          }
        }
      });

      if (window.document.documentMode) {
        // This is for IE 11
        window.clipboardData.setData("Text", questionsAndAnswers.innerText);
      } else {
        navigator.clipboard.writeText(questionsAndAnswers.innerText);
      }

      questionsAndAnswers.remove();
      copyToClipboardBtn.style.display = "none";
      copiedBtn.style.display = "inline-block";

      setTimeout(function () {
        copyToClipboardBtn.style.display = "inline-block";
        copiedBtn.style.display = "none";
      }, 2000);
    },

    scrollToTopOfQAB() {
      document
        .getElementById("app")
        .scrollIntoView({ behavior: "smooth" }, true);
    },
  },
};
</script>
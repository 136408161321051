<template>
  <div id="app">
    <main id="main" class="qrs-typography">
      <div id="app-container" class="app__container">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
import { dataService } from "./shared";

Vue.use(VueRouter);
Vue.use(Vuex);

export default {
  name: "App",

  data() {
    return {
      data: null,
      json: window.qabpData,
    };
  },

  created() {
    let json = dataService.getQuestions();
    let steps = json.steps;
    let currentStep = dataService.findElement(steps, dataService.parseUrl());
    let firstStep = steps[0].responseId;

    let stepPosition = steps.indexOf(currentStep);
    this.data = steps;

    if (stepPosition > 0) {
      if (currentStep.responseId !== json.start) {
        if (json.start.length > 1) {
          this.$router.replace({ name: json.start });
        } else {
          this.$router.replace({ name: firstStep });
        }
      }
    }
  }
};
</script>

<style lang="sass">
@import '/assets/style/app.scss'
</style> 
<template>
<div class="accordion govuk-accordion__section">
  <div class="accordion__header js-enabled govuk-accordion__section-header">
    <button
      @click="toggleAccordion()"
      class="govuk-accordion__section-button"
      :aria-expanded="isOpen"
      :aria-controls="`collapse${_uid}`"
    >
      <slot name="title" />
      <icon class="accordion__header--icon" v-show="!isOpen" icon="plus" />
      <icon class="accordion__header--icon" :class="isOpen ? 'accordion--active' : ''" v-show="isOpen" icon="minus" />
    </button>
  </div>
      <div class="" v-show="isOpen" :id="`collapse${_uid}`">
      <slot name="content" />
    </div>
</div>
</template>

<script>

// export default {        
//   props: {
//     title: String,
//     content: String,
//     image: String
//   },

//   data() {
//     return {
//       active: false,
//     }
//   }
// }

export default {
  name: "Accordion",
  
  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
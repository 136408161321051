<template>
    <span class="circle">
    </span>
</template>

<script>
export default {
    name: "Loader",
};
</script>

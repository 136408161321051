import Vue from 'vue';
import Router from 'vue-router';
import DecisionStep from '../views/DecisionStep.vue';
import Intro from '../views/Intro.vue';
import Statement from '../views/Statement.vue';
import Outcome from '../views/Outcome.vue';
import PreviewOutcome from '../views/PreviewOutcome.vue';
import NavigationStep from '../views/NavigationStep.vue'
// import json from '../../public/api/cms_data.json';

let json = window.qabpData;
let pageLocation = window.pageLocation;

const createRoutes = function () {
  let firstStep;

  if (json.start.length > 1) {
    firstStep = json.start;
  } else {
    firstStep = json.steps[0].responseId;
  }

  let routeData = json.steps.map(({ responseId, type }) => ({
    path: `/${responseId}`,
    name: responseId.toLowerCase(),
    component: getComponent(type)
  }));
  routeData.unshift({ path: '/', redirect: '/' + firstStep });

  return routeData;
};

const getComponent = function (Type) {
  switch (Type) {
    case "Intro":
      return Intro;
    case "DecisionStep":
      return DecisionStep;
    case "Statement":
      return Statement;
    case "Outcome":
      return Outcome;
    case "PreviewOutcome":
      return PreviewOutcome;
    case "NavigationStep":
      return NavigationStep
  }
};

Vue.use(Router)
export default new Router({
  mode: "hash",
  base: pageLocation,
  routes: createRoutes()
});
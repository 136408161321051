<template>
    <div class="gamma">
        Section {{section}} of 30
    </div>
</template>

<script>
export default {
	name: 'Section',
	props: {
		section: Number
	}
}
</script>
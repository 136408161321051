<template>
  <div v-bind:class="[getClass]">
    <h1 class="panel__title">
        {{ title }}
		</h1>
		<div class="panel__body">
        {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  props: {
		title: String,
		message: String,
		variant: {
			type: String,
			default: "info"
		}
	},
	data(){
		return {
			getClass : this.variant === null ? null : "panel push__top panel--" + this.variant
		}
	}
}
</script>
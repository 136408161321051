<template>
	<div class="form-group" :class="{ 'form-group--error' : validate.$error}">
		<fieldset class="fieldset">	
			<legend class="fieldset__legend fieldset__legend--xl">
				<h1>{{title}}</h1>
			</legend>
			<slot name="content"></slot>
			<span v-if="validate.$error" :id="getErrorId" class="error-message">
				{{errorMessage}}
			</span>	
			<slot></slot>
		</fieldset>
	</div>
</template>

<script>
export default {
	name: 'IntroForm',
	props: {
		id: String,
		title: String,
		error: Boolean,
		errorMessage: String,
		validate: Object
	},
	data() {
		return {
			getError: this.error ? "form-group--error" : null,
			getErrorId: this.id + "-error",
			getId: this.id + "-hint",
		}
	}
}
</script>
<template>
  <div class="wysiwyg" :class="bg" v-html="content" v-if="content != null"/>
</template>

<script>
export default {
	name: 'Wysiwyg',
	props: {
		content: String,
		background: String,
	},
	data() {
		return {
			bg: this.background != "" ? "soft bg__"+ this.background : ""
		}
	}
}
</script>
import BackLink from './BackLink.vue'; 
import BackButton from './BackButton';
import Button from './Button.vue'; 
import Checklist from './Checklist.vue'; 
import Details from './Details.vue'; 
import InsetText from './InsetText.vue'; 
import Panel from './Panel.vue';
import Section from './Section.vue';
import Wysiwyg from './Wysiwyg.vue'; 
import Accordion from './Accordion.vue';

export default  {
	BackLink,
	BackButton,
	Button,
	Checklist,
	Details,
	InsetText,
	Panel,
	Section,
	Wysiwyg,
	Accordion
};
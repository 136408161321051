<template>
  <router-link :to="url" class="btn" :class="[getClass, getStart]">{{text}}</router-link>
</template>

<script>
export default {
	name: 'Button',
	props: {
		url: String,
		text: String,
		isStart: Boolean,
		variant: {
			type: String,
			default: "primary"
		}
	},
	data(){
		return {
			getClass : this.variant === null ? null : "btn--" + this.variant,
			getStart : this.isStart === false ?  null : "btn--start"
		}
	}
}
</script>
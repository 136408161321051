<template>
  <section :id="id" class="statement">
    <!-- <BackLink /> -->
    <h1 id="stepTitle">{{ title }}</h1>
    <div id="bodyContent" v-html="bodyContent"></div>

    <div
      v-if="topSection != null && topSection.length != 0"
      class="accordion govuk-accordion__section"
    >
      <div class="accordion__header js-enabled govuk-accordion__section-header">
        <button
          @click="toggleTopAccordion()"
          class="govuk-accordion__section-button"
          :aria-expanded="topSectionOpenOnLoad"
          :aria-controls="`collapse-top-section`"
        >
          <p v-html="topSection.title" class="m-0"></p>
          <icon
            class="accordion__header--icon"
            v-show="!topSectionOpenOnLoad"
            icon="plus"
          />
          <icon
            class="accordion__header--icon"
            :class="topSectionOpenOnLoad ? 'accordion--active' : ''"
            v-show="topSectionOpenOnLoad"
            icon="minus"
          />
        </button>
      </div>
      <div v-show="topSectionOpenOnLoad" :id="`collapse-top-section`">
        <p v-html="topSection.body"></p>
      </div>
    </div>

    <article>
      <Form @submit="submitStatement"></Form>
    </article>

    <div
      v-if="bottomSection != null && bottomSection.length != 0"
      class="accordion govuk-accordion__section"
    >
      <div class="accordion__header js-enabled govuk-accordion__section-header">
        <button
          @click="toggleBottomAccordion()"
          class="govuk-accordion__section-button"
          :aria-expanded="bottomSectionOpenOnLoad"
          :aria-controls="`collapse-bottom-section`"
        >
          <p v-html="bottomSection.title" class="m-0"></p>
          <icon
            class="accordion__header--icon"
            v-show="!bottomSectionOpenOnLoad"
            icon="plus"
          />
          <icon
            class="accordion__header--icon"
            :class="bottomSectionOpenOnLoad ? 'accordion--active' : ''"
            v-show="bottomSectionOpenOnLoad"
            icon="minus"
          />
        </button>
      </div>
      <div v-show="bottomSectionOpenOnLoad" :id="`collapse-bottom-section`">
        <p v-html="bottomSection.body"></p>
      </div>
    </div>
  </section>
</template>

<script>
import { dataService } from "../shared";

export default {
  name: "Statement",

  data() {
    return {
      id: "",
      sectionId: null,
      stepType: null,
      title: "",
      bodyContent: "",
      topSection: null,
      bottomSection: null,
      topSectionOpenOnLoad: false,
      bottomSectionOpenOnLoad: false,
      nextUrl: "",
    };
  },

  created() {
    this.loadQuestion();
  },

  methods: {
    toggleTopAccordion() {
      this.topSectionOpenOnLoad = !this.topSectionOpenOnLoad;
    },
    toggleBottomAccordion() {
      this.bottomSectionOpenOnLoad = !this.bottomSectionOpenOnLoad;
    },
    scrollToTopOfQAB() {
      document
        .getElementById("app-container")
        .scrollIntoView({ behavior: "smooth" }, true);
    },
    submitStatement() {
      this.$router.push({ name: this.nextUrl.toLowerCase() });
      this.scrollToTopOfQAB();
    },
    loadQuestion() {
      this.scrollToTopOfQAB();
      
      let json = dataService.getQuestions();
      let steps = json.steps;
      let currentStep = dataService.findElement(steps, dataService.parseUrl());
      let topContentSections = currentStep.content.topContentSections;
      let bottomContentSections = currentStep.content.bottomContentSections;

      this.id = currentStep.id;
      this.title = currentStep.title;
      this.bodyContent = currentStep.content.body;
      topContentSections.forEach((element) => {
        let topContent = {};
        topContent.title = element.title;
        topContent.body = element.body;
        topContent.openOnLoad = element.openOnLoad;
        this.topSectionOpenOnLoad = element.openOnLoad;
        this.topSection = topContent;
      });
      bottomContentSections.forEach((element) => {
        let bottomContent = {};
        bottomContent.title = element.title;
        bottomContent.body = element.body;
        bottomContent.openOnLoad = element.openOnLoad;
        this.bottomSectionOpenOnLoad = element.openOnLoad;
        this.bottomSection = bottomContent;
      });
      // this.nextUrl = currentStep.destination;

      currentStep.responses.forEach((element) => {
        this.nextUrl = element.path;
      });
    },
  },
};
</script>
<template>
  <section :id="id" class="outcome">

    <h1 id="stepTitle">{{ stepTitle }}</h1>
    <div id="stepDescription" v-if="stepDescription.length > 0">
      <p v-html="stepDescription"></p>
    </div>

    <div v-for="(item, itemIdx) in stepsAndQuestions" v-bind:key="itemIdx">
      <div v-if="item.hasResponse">
        <div class="outcome-question-parent-element">
          <h2 class="outcome-question-child-element-rigth">{{ item.id }}</h2>
          <div class="outcome-question-child-element-left">
            <a class="govuk-link" @click="$router.push(item.responseId)">
              Change<span class="govuk-visually-hidden"> {{ item.id }}</span>
            </a>
          </div>
        </div>
        <div v-for="(inneritem, innerIdx) in item.questions" v-bind:key="innerIdx"
          class="govuk-summary-list__row summary-list__row">
          <div class="summary-list__row" v-if="inneritem.type === 'TextArea'">
            <div v-for="(answerItem, answerIdx) in inneritem.res" v-bind:key="answerIdx">
              <div v-for="(response, resIdx) in answerItem" v-bind:key="resIdx" v-html="response.response">
              </div>
            </div>
          </div>
          <div class="summary-list__row" v-else>
            <dt class="govuk-summary-list__key summary-list__item">
              {{ inneritem.label }}
            </dt>
            <dd class="govuk-summary-list__value summary-list__item">
              <div v-for="(answerItem, answerIdx) in inneritem.res" v-bind:key="answerIdx">
                <div v-if="!inneritem.res.length || inneritem.data.length === 0">
                  <p>No answer provided</p>
                </div>
                <div v-else>
                  <div v-for="(response, resIdx) in answerItem" v-bind:key="resIdx" v-html="response.response">
                  </div>
                </div>
              </div>
            </dd>
          </div>
        </div>
      </div>
    </div>
    <div class="button__container mt--48">
      <BackButton v-if="previousUrl === ''" />
      <a @click="$router.push(previousUrl)" v-else class="govuk-button govuk-button--neutral"
        :class="{ 'mr--16': !inApp }" role="button">Previous</a>
      <a @click="submit" class="govuk-button govuk-button" :class="{ 'mr--16': !inApp }" role="button">
        {{ isVisible ? '' : submitButton }}<Loader v-if="isVisible"></Loader></a>
    </div>
    <div class="backdrop" v-if="isVisible">
    </div>
  </section>
</template>

<script>
import { dataService, navigationService } from "../shared";
import scoringService from "../shared/scoring.js";
import Loader from "../components/Forms/Loader.vue";

export default {
  data() {
    return {
      id: "",
      name: "",
      title: "",
      stepTitle: "",
      answers: null,
      steps: null,
      data: null,
      res: null,
      submitButton: "Submit",
      submitUrl: "",
      errorUrl: "",
      stepDescription: "",
      pdfSummaryMultipleScorings: null,
      pdfSummarySingleScorings: null,
      pdfSummaryPatientInfo: null,
      pdfSummaryEmail: null,
      scoringCriteria: null,
      additionalInfo: {
        isAdd: false,
        questionId: ''
      },
      isVisible: false,
      previousUrl: '',
      stepsAndQuestions: []
    };
  },
  components: {
    Loader,
  },
  created() {
    this.loadQuestion();
    this.previousUrl = navigationService.setNaviagtionArray(this.name);
  },
  mounted() {
    this.loadAnswers();
    this.getStepData();
  },
  computed: {},

  methods: {
    async submit() {

      let eligible = scoringService.isNotEligibleWitReason(this.answers);
      let patientInfo = scoringService.getPatientInfoSummary(this.pdfSummaryPatientInfo, this.answers);
      let isResponseSuccess = false;

      if (eligible.isNotEligible) {
        patientInfo.therapyStatus = eligible.isReceivingPsychologicalTherapy ? eligible.reasons[0] : patientInfo.therapyStatus;
        let response = {
          email: this.pdfSummaryEmail,
          patientInfo: patientInfo,
          eligible: eligible
        };

        isResponseSuccess = await this.generateAndSendPdf(response);

      } else {
        let multipleScoring = scoringService.calculateMultipleSummary(this.pdfSummaryMultipleScorings, this.answers);
        let singleScoring = scoringService.calculateSingleSummary(this.pdfSummarySingleScorings, this.answers);
        let additionalInfo = this.additionalInfo.isAdd ?
          scoringService.getAdditionalInfoSummary(this.additionalInfo.questionId, this.answers) :
          '';

        let scoringsOverview = scoringService.questionScoringOverview(this.pdfSummarySingleScorings, this.answers);

        let response = {
          eligible: eligible,
          email: this.pdfSummaryEmail,
          patientInfo: patientInfo,
          multipleScoring: multipleScoring,
          singleScoring: singleScoring,
          informationFromPatient: additionalInfo,
          scoringOverview: scoringsOverview,
          scoringCriteria: this.scoringCriteria
        };

        isResponseSuccess = await this.generateAndSendPdf(response);
      }

      if (isResponseSuccess) {
        this.$router.push(this.submitUrl);
      } else {
        this.$router.push(this.errorUrl);
      }
    },

    async generateAndSendPdf(response) {
      try {
        this.isVisible = true;

        await fetch(
          "https://eds.scot.nhs.uk/Umbraco/Api/PdfSummary/GeneratePdf",
          // "https://tac-eduni-seds-app-liv.azurewebsites.net/Umbraco/Api/PdfSummary/GeneratePdf",
          // "https://tac-eduni-seds-app-dev-0-tst.azurewebsites.net/Umbraco/Api/PdfSummary/GeneratePdf",
          // 'https://localhost:44397/Umbraco/Api/PdfSummary/GeneratePdf',
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(response),
          }
        ).then(response => {
          if (!response.ok) {
            throw new Error(response.status)
          }
        });

        return true;
      }
      catch (err) {
        return false;
      }
      finally {
        this.isVisible = false;
      }
    },

    loadQuestion() {
      let json = dataService.getQuestions();
      let steps = json.steps;
      let currentStep = dataService.findElement(steps, dataService.parseUrl());

      this.id = currentStep.id;
      this.name = currentStep.responseId;
      this.title = json.title;
      this.stepTitle = currentStep.title;
      this.stepDescription = currentStep.content.body;
      this.submitUrl = currentStep.defaultDestination;
      this.errorUrl = currentStep.destinationWhenError;
      this.pdfSummaryMultipleScorings = currentStep.pdfSummaryWithScoring.multipleScoringSummary;
      this.pdfSummarySingleScorings = currentStep.pdfSummaryWithScoring.singleScoringSummary;
      this.pdfSummaryPatientInfo = currentStep.pdfSummaryWithScoring.patientInfoSummary;
      this.additionalInfo.isAdd = currentStep.pdfSummaryWithScoring.isAdditionalInfoSummary;
      this.additionalInfo.questionId = currentStep.pdfSummaryWithScoring.additionalInfoSummary;
      this.pdfSummaryEmail = currentStep.pdfSummaryWithScoring.emailSettings;
      this.scoringCriteria = currentStep.pdfSummaryWithScoring.scoringCriteria;
      this.steps = steps;
    },

    loadAnswers() {
      let responses = dataService.getCompleteData();
      this.answers = responses;
    },

    isResponseExist(responses) {
      return !responses.find(s => s.response.length === 0);
    },

    getStepData() {
      let stepResponses = [];

      for (let e in this.answers) {
        let answer = this.answers[e];
        stepResponses.push(answer.responses);
      }

      for (let i in this.steps) {
        let item = this.steps[i];
        if (item.type === "DecisionStep") {
          let stepData = {
            id: item.id,
            hasResponse: true,
            questions: [],
            responseId: item.responseId,
          };
          for (let innerItem in item.questions) {
            let question = item.questions[innerItem];
            stepData.questions.push({
              id: question.questionId,
              label: question.label,
              data: question.response,
              conditional: question.isConditional,
              parentTriggered: false,
              res: [],
              type: question.type,
            });

            if (question.type === "TextArea" && !question.response.length) {
              stepData.hasResponse = false;
            }
          }
          this.stepsAndQuestions.push(stepData);
        }
      }

      this.stepsAndQuestions.forEach((e) => {
        let parentTrigger = "";

        for (let el in stepResponses) {
          let element = stepResponses[el];

          if (element.length >= 1) {
            for (let data in element) {
              let i = element[data];

              if (i.response.length > 0) {
                for (let r in i.response) {
                  let res = i.response[r];
                  parentTrigger = res.trigger;
                }
              }
              e.questions.forEach((e) => {
                if (i.responseId === e.id && i.response.length >= 0) {
                  e.res.push(i.response);
                  if (i.responseId === parentTrigger) {
                    e.parentTriggered = true;
                  }
                }
              });
            }
          }
        }

        if (e.questions.length >= 1) {
          for (let r in e.questions) {
            let itemRes = e.questions[r];
            if (itemRes.res.length == 0) {
              e.hasResponse = false;
            }
          }
        }
      });
    },

    scrollToTopOfQAB() {
      document
        .getElementById("app")
        .scrollIntoView({ behavior: "smooth" }, true);
    },
  },
};
</script>


<template>
	<div>
		<a href="javascript:history.go(-1)" v-if="!checkLink" class="back-link">Go Back</a>
	</div>
</template>

<script>
export default {
	name: 'BackLink',
	data() {
		return {
			prevRoute: "",
			checkLink: window.location.href.indexOf("Intro") > -1,
		}
	}
}
</script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCog,
  faExternalLinkAlt,
  faSave,
  faSort,
  faSpinner,
  faPen,
  faEdit,
  faTimes,
  faTrash,
  faPlus,
  faCaretRight,
  faCaretDown,
  faInfoCircle,
  faSubscript,
  faSuperscript,
  faUndoAlt,
  faUpload,
  faExclamationTriangle,
  faExclamationCircle,
  faCheck,
  faMinus,
  faChevronDown,
  faChevronUp,
  faEnvelope,
  faSearch,
  faBell,
  faBars,
  faChevronRight,
  faChevronLeft,
  faSitemap,
  faMobile,
  faMobileAlt,
  faUser,
  faLocationArrow,
  faBriefcase,
  faPhone,
  faBuilding,
  faMapMarkerAlt,
  faShare,
  faInfo,
  faDownload,
  faFrown,
  faSmile,
  faMeh,
  faCopy
} from "@fortawesome/free-solid-svg-icons";/**
* Add Icons to Font Library
* Only these will get used rest of library is disregarded.
*/
library.add(
 faCog,
 faCopy,
 faExternalLinkAlt,
 faSave,
 faSpinner,
 faSort,
 faPen,
 faTimes,
 faTrash,
 faPlus,
 faMinus,
 faEdit,
 faCaretRight,
 faCaretDown,
 faChevronDown,
 faChevronUp,
 faInfoCircle,
 faExclamationTriangle,
 faExclamationCircle,
 faEnvelope,
 faBell,
 faBars,
 faSitemap,
 faPhone,
 faMobile,
 faMobileAlt,
 faUser,
 faLocationArrow,
 faMapMarkerAlt,
 faBriefcase,
 faSearch,
 faCheck,
 faBuilding,
 faSubscript,
 faSuperscript,
 faUndoAlt,
 faChevronRight,
 faChevronLeft,
 faUpload,
 faShare,
 faInfo,
 faDownload,
 faFrown,
 faSmile,
 faMeh
);

export default {
    FontAwesomeIcon
};
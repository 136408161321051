<!-- @format -->

<template>
	<section :id="id" class="decision-step">
		<h1 id="stepTitle" v-if="stepTitleVisible">{{ stepTitle }}</h1>
		<div id="stepDescription" v-if="stepDescription.length > 0">
			<p v-html="stepDescription"></p>
		</div>

		<!-- ERROR VALIDATION -->

		<div v-if="mandatoryQuestions.length > 0" id="email-error-send" class="govuk-error-summary"
			aria-labelledby="error-summary-title" role="alert" tabindex="-1" data-module="govuk-error-summary">
			<h2 class="govuk-error-summary__title" id="error-summary-title">There is a problem</h2>
			<div class="govuk-error-summary__body">
				<ul class="govuk-list govuk-error-summary__list">
					<li v-for="(mandatoryQuestion, mqIdx) in mandatoryQuestions" v-bind:key="mqIdx">
						<a @click="goToErrorField(mandatoryQuestion.id, mandatoryQuestion.type)">Please select an answer for
							"{{ mandatoryQuestion.label }}"</a>
					</li>
				</ul>
			</div>
		</div>

		<!-- END -->
		<article>
			<Form :data="form" :formId="id" :formErrorMessage="errorMessage" @submit="submitFormQuestion"
				:firstStep="firstStep" :previousUrl="previousUrl">
				<div v-for="(item, itemIdx) in stepQuestions" v-bind:key="itemIdx">
					<!-- CHECKBOX LIST QUESTION -->

					<div :id="item.questionId" :class="{
						conditional__element: item.isConditional,
						checkboxlist__element: item.isConditional,
						hide: item.isConditional,
					}" v-if="item.type == 'CheckboxList'">
						<!-- NONE OF THE ABOVE QUESTION -->

						<div v-if="item.includeNoneOfTheAbove">
							<fieldset class="govuk-fieldset mb--48" :name="item.label + ' ' + itemIdx">
								<span :id="item.label + '-field-error__message'" class="field-error__message hide">Please
									select an answer</span>
								<legend class="govuk-fieldset__legend govuk-fieldset__legend--m">
									<h2 v-if="item.label" class="govuk-fieldset__heading">
										{{ item.label }}
										<span v-if="item.isMandatory" class="mandatory">*</span>
									</h2>
									<span v-html="item.hintText"></span>
								</legend>
								<div class="govuk-checkboxes">
									<div :id="'noneOfTheAbove-' + item.questionId">
										<div v-for="(option, optionIdx) in item.optionGroups" :key="item.label + optionIdx">
											<h3 v-if="option.groupTitle.length > 0">
												{{ option.groupTitle }}
											</h3>
											<div class="govuk-checkboxes__item">
												<input class="govuk-checkboxes__input"
													:id="item.questionId + ' ' + optionIdx" :name="item.questionId"
													type="checkbox" :value="{
														id: option.id,
														label: item.label,
														questionType: item.type,
														response: option.label,
														isConditional: item.isConditional,
														score: option.score,
													}" v-model="item.response" @change="checkboxChangeNoneOfTheAbove(item, itemIdx)" />
												<label class="govuk-label govuk-checkboxes__label"
													:for="item.questionId + ' ' + optionIdx" v-html="option.label"></label>
											</div>
										</div>
									</div>
									<div class="govuk-checkboxes__divider">
										<h3>Or</h3>
									</div>
									<div class="govuk-checkboxes__item">
										<input class="govuk-checkboxes__input" :id="item.questionId + 'none'"
											:name="item.questionId" type="checkbox" :value="{
												id: 'none',
												label: item.label,
												questionType: item.type,
												response: 'None of the above',
												isConditional: item.isConditional,
												score: 0,
											}" v-model="item.response" @change="checkboxNoneChange(item, itemIdx)" />
										<label class="govuk-label govuk-checkboxes__label" :for="item.questionId + 'none'">
											<span v-if="item.noneOfTheAboveLabel.length > 0">{{ item.noneOfTheAboveLabel
											}}</span>
											<span v-else>None of the above</span>
										</label>
									</div>
								</div>
							</fieldset>
						</div>
						<!-- END -->

						<!-- CHECKBOX SINGLE QUESTION -->
						<div :id="item.questionId" v-else>
							<fieldset class="govuk-fieldset mb--48" :name="item.label + ' ' + itemIdx">
								<span :id="item.label + '-field-error__message'" class="field-error__message hide">Please
									select an answer</span>
								<legend class="govuk-fieldset__legend govuk-fieldset__legend--m">
									<h2 v-if="item.label" class="govuk-fieldset__heading">
										{{ item.label }}
										<span v-if="item.isMandatory" class="mandatory">*</span>
									</h2>
									<span v-html="item.hintText"></span>
								</legend>
								<div v-for="(option, optionIdx) in item.optionGroups" :key="item.label + optionIdx">
									<h3 v-if="option.groupTitle.length > 0">
										{{ option.groupTitle }}
									</h3>
									<div class="govuk-checkboxes">
										<div class="govuk-checkboxes__item">
											<input class="govuk-checkboxes__input" :id="item.questionId + ' ' + optionIdx"
												:name="item.questionId" type="checkbox" :value="{
													id: option.id,
													questionType: item.type,
													response: option.label,
													isConditional: item.isConditional,
													score: option.score,
												}" v-model="item.response" @change="checkboxChange(item, itemIdx)" />
											<label class="govuk-label govuk-checkboxes__label"
												:for="item.questionId + ' ' + optionIdx" v-html="option.label"></label>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<!-- END -->
						<!-- {{ item.response }} -->
					</div>
					<!-- END -->

					<!-- RADIO BUTTON LIST QUESTION Horizontal -->
					<div :class="{
						conditional__element: item.isConditional,
						radiolist__element: item.isConditional,
						hide: item.isConditional,
					}" :id="item.questionId" v-if="item.type == 'RadioListHorizontally' && isMobileView === false">
						<fieldset class="govuk-fieldset mb--48" :name="item.label + ' ' + itemIdx">
							<span :id="item.label + '-field-error__message'" class="field-error__message hide">Please select
								an answer</span>
							<div
								v-bind:class="{ 'radio-list__container': !isTabletView, 'container-column': isTabletView }">
								<div class="radio-list__container-item">
									<legend class="govuk-fieldset__legend govuk-fieldset__legend--m">
										<h2 v-if="item.label" class="govuk-fieldset__heading">
											{{ item.label }}
											<span v-if="item.isMandatory" class="mandatory">*</span>
										</h2>
									</legend>
								</div>
								<div
									class="govuk-radios govuk-radios--inline radio-button__container radio-list__container-item">
									<span v-for="option in item.optionGroups" :key="item.label + option.id + 'label'"
										v-html="option.label" class="radio-button__container-item"></span>
									<div v-for="option in item.optionGroups" :key="item.label + option.id + 'input'"
										class="radio-button__container-item">
										<input class="govuk-radios__input" :id="item.questionId + ' ' + option.id"
											:name="'radio-' + item.questionId" type="radio" :value="{
												id: option.id,
												questionType: item.type,
												response: option.label,
												isConditional: item.isConditional,
												score: option.score,
												trigger: option.conditionalTrigger,
											}" v-model="item.response" @change="radioChange(item, itemIdx)"
											@click="item.response = radioUnselect(item, itemIdx)" />
										<label class="govuk-label govuk-radios__label"
											:for="item.questionId + ' ' + option.id">
											<span class="visually-hidden">Radio Button {{ option.label }}</span>
										</label>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
					<!-- END -->

					<!-- RADIO BUTTON LIST QUESTION Vertical-->
					<div :class="{
						conditional__element: item.isConditional,
						radiolist__element: item.isConditional,
						hide: item.isConditional,
					}" :id="item.questionId"
						v-if="item.type == 'RadioList' || (isMobileView === true && item.type == 'RadioListHorizontally')">
						<fieldset class="govuk-fieldset mb--48" :name="item.label + ' ' + itemIdx">
							<span :id="item.label + '-field-error__message'" class="field-error__message hide">Please select
								an answer</span>
							<legend class="govuk-fieldset__legend govuk-fieldset__legend--m">
								<h2 v-if="item.label" class="govuk-fieldset__heading">
									{{ item.label }}
									<span v-if="item.isMandatory" class="mandatory">*</span>
								</h2>
							</legend>
							<span v-html="item.hintText"></span>
							<div class="mt--24" v-for="option in item.optionGroups" :key="item.label + option.id">
								<h3 v-if="option.groupTitle.length > 0">
									{{ option.groupTitle }}
								</h3>
								<div class="govuk-radios">
									<div class="govuk-radios__item">
										<input class="govuk-radios__input" :id="item.questionId + ' ' + option.id"
											:name="'radio-' + item.questionId" type="radio" :value="{
												id: option.id,
												questionType: item.type,
												response: option.label,
												isConditional: item.isConditional,
												score: option.score,
												trigger: option.conditionalTrigger,
											}" v-model="item.response" @change="radioChange(item, itemIdx)" />
										<label class="govuk-label govuk-radios__label"
											:for="item.questionId + ' ' + option.id" v-html="option.label"></label>
									</div>
								</div>
							</div>
						</fieldset>
						<!-- {{ item.response }} -->
					</div>
					<!-- END -->

					<!-- DROPDOWN SELECT QUESTION -->
					<div :class="{
						conditional__element: item.isConditional,
						dropdown__element: item.isConditional,
						hide: item.isConditional,
					}" :id="item.questionId" v-if="item.type == 'Dropdown'">
						<div class="govuk-form-group">
							<fieldset class="govuk-fieldset mb--48" :name="item.label + ' ' + itemIdx">
								<span :id="item.label + '-field-error__message'" class="field-error__message hide">Please
									select an answer</span>
								<legend class="govuk-fieldset__legend govuk-fieldset__legend--m">
									<h2 v-if="item.label" class="govuk-fieldset__heading">
										{{ item.label }}
										<span v-if="item.isMandatory" class="mandatory">*</span>
									</h2>
									<span v-html="item.hintText"></span>
								</legend>
								<label class="govuk-label" :for="'select-' + item.questionId">
									{{ item.label }}
								</label>
								<select class="govuk-select" :id="'select-' + item.questionId"
									:name="'select-' + item.questionId" v-model="item.response"
									@change="selectChange(item, itemIdx)">
									<option selected disabled>Select option</option>
									<option v-for="option in item.optionGroups" :key="item.label + option.id" :value="{
										id: option.id,
										questionType: item.type,
										response: option.label,
										isConditional: item.isConditional,
										score: option.score,
										trigger: option.conditionalTrigger,
									}">
										<span v-html="option.label"></span>
									</option>
									<!-- <optgroup :label="option.groupTitle" v-for="option in item.optionGroups" :key="item.label + option.id">
                    <option v-html="option.label"
                    :value="{
id: option.id,
questionType: item.type,
response: option.label,
isConditional: item.isConditional,
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				score: option.score,trigger: option.conditionalTrigger,}"></option>
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				</optgroup> -->
								</select>
							</fieldset>
							<!-- {{ item.response }} -->
						</div>
					</div>
					<!-- END -->

					<!-- TEXT INPUT QUESTION -->
					<div :id="item.questionId" v-if="item.type == 'TextInput'">
						<div class="govuk-form-group">
							<h1 class="govuk-label-wrapper">
								<label class="govuk-label govuk-label--m" :for="'textinput-' + item.questionId">
									{{ item.label }}
									<span v-if="item.isMandatory" class="mandatory">*</span>
								</label>
							</h1>
							<span :id="item.label + '-field-error__message'" class="field-error__message hide">{{
								item.label }} is required</span>
							<input class="govuk-input" :id="'textinput-' + item.questionId"
								:name="'textinput' + item.questionId" v-html="item.label" type="text"
								v-model="item.response" @change="textInputChange(item, itemIdx)" />
						</div>
					</div>
					<!-- END -->

					<!-- TEXT AREA QUESTION -->
					<div :id="item.questionId" v-if="item.type == 'TextArea'">

						<div class="govuk-form-group">
							<label class="govuk-label" :for="'textarea-' + item.questionId">
								<h1 class="govuk-label-wrapper" v-html="item.label">
								</h1>
							</label>
							<div id="more-detail-hint" class="govuk-hint" v-html="item.hintText">
							</div>
							<textarea v-model="item.response" class="govuk-textarea" :id="'textarea-' + item.questionId"
								:name="'textarea' + item.questionId" @change="textInputChange(item, itemIdx)" rows="10"
								aria-describedby="more-detail-hint"></textarea>
						</div>
					</div>
					<!-- END -->

					<!-- DATE INPUT QUESTION (Current version allows only one per page)-->
					<div :id="item.questionId" v-if="item.type == 'DateInput'">
						<div class="govuk-form-group">
							<fieldset class="govuk-fieldset" role="group">
								<span :id="item.label + '-field-error__message'" class="field-error__message hide">{{
									item.label }} is required</span>
								<div v-if="dateErrors">
									<p v-for="(error, errorIdx) in dateErrors.errors" v-bind:key="errorIdx"><span
											:id="item.label + '-field-error__date-validations-' + errorIdx"
											class="field-error__message hide">{{
												error }}</span></p>
								</div>
								<legend class="govuk-fieldset__legend govuk-fieldset__legend--m">
									<h1 class="govuk-fieldset__heading">
										{{ item.label }}
										<span v-if="item.isMandatory" class="mandatory">*</span>
									</h1>
								</legend>
								<DateInput :date="date" :item="item"
									@change="(event) => dateInputChange(event, item, itemIdx)">
								</DateInput>
							</fieldset>
						</div>
						<!-- END -->
					</div>
				</div>
				<Modal v-if="confirmationDialog.turnOn && confirmationDialog.show"
					@confirm="(isConfirm) => closeDialog(isConfirm)"></Modal>
			</Form>
		</article>
	</section>
</template>

<script>
import { dataService, navigationService } from '../shared';

export default {
	name: 'DecisionStep',
	props: {
		title: String,
	},

	data() {
		return {
			date: null,
			id: '',
			firstStep: false,
			sectionId: null,
			stepData: null,
			stepQuestions: [],
			mandatoryQuestions: [],
			formIsValid: false,
			stepType: null,
			stepTitle: '',
			stepTitleVisible: null,
			stepDescription: '',
			error: false,
			errorMessage: 'All fields are required',
			name: '',
			answer: null,
			nextUrl: '',
			previousUrl: '',
			defaultDestination: null,
			myResponses: null,
			cmsData: this.$CMSData,
			form: {
				responses: [],
			},
			dateErrors: null,
			underAgeOption: {
				tag: 'dateofbirth',
				condition: 16
			},
			isMobileView: false,
			isTabletView: false,
			confirmationDialog: {
				turnOn: false,
				show: false,
				isCondition: false
			}
		};
	},
	created() {
		this.loadQuestion();
		this.isMobileView = this.isMobile();
		this.isTabletView = this.isTablet();
		this.previousUrl = navigationService.setNaviagtionArray(this.name);
	},
	mounted() {
		this.loadAnswer();
	},
	validations: {
		form: {},
	},
	methods: {
		dateInputChange(event, item, index) {
			let option = item.optionGroups[0];
			let questionId = item.questionId;
			const options = { year: "numeric", month: "2-digit", day: "2-digit" };
			let underAgeDate = new Date().setFullYear(new Date().getFullYear() - this.underAgeOption.condition);

			let response = {
				id: option.id,
				isConditional: item.isConditional,
				questionType: item.type,
				response: new Date().toLocaleDateString("en-GB", options).replace(/\//g, "-"),
				score: 0,
				trigger: item.conditionalTrigger
			};

			if (event.validation.isValid) {
				let dateString = event.date.toLocaleDateString("en-GB", options).replace(/\//g, "-");

				response.response = event.date !== null ? dateString : "";
				response.score = option.label.toLowerCase().replace(/\s/g, "").includes(this.underAgeOption.tag) ? event.date > underAgeDate ? 2 : 0 : 0;
			}

			this.form.responses[index] = {
				[questionId]: response,
			};

			this.stepQuestions[index].response = response.response;

			this.dateErrors = event.validation;
		},

		closeDialog(isConfirm) {
			if (isConfirm) {
				this.$router.push({ name: this.nextUrl.toLowerCase() });
			}
			this.confirmationDialog.show = false;
		},

		isMobile() {
			if (screen.width <= 425) {
				return true;
			}
			else {
				return false;
			}
		},

		isTablet() {
			if (screen.width >= 425 && screen.width <= 768) {
				return true;
			}
			else {
				return false;
			}
		},

		submitTestForm() {
			dataService.setTestData();
		},

		resetConditionalQuestion(question) {
			this.stepQuestions.forEach((element) => {
				if (element.questionId.includes(question)) {
					element.response = [];
				}
			});
		},

		checkboxChange(item, index) {
			let QuestionId = item.questionId;
			this.form.responses[index] = {
				[QuestionId]: item.response,
			};
		},

		textInputChange(item, index) {
			let option = item.optionGroups[0];
			let questionId = item.questionId;

			let response = {
				id: option.id,
				isConditional: item.isConditional,
				questionType: item.type,
				response: item.response,
				score: option.score,
				trigger: item.conditionalTrigger
			};
			this.form.responses[index] = {
				[questionId]: response,
			};
		},

		checkboxChangeNoneOfTheAbove(item, index) {
			let QuestionId = item.questionId;

			if (item.response[0] != null && item.response[0].id === 'none') {
				item.response = item.response.slice(-1);
			}
			this.form.responses[index] = {
				[QuestionId]: item.response,
			};
		},

		checkboxNoneChange(item, index) {
			let parentElement = 'noneOfTheAbove-' + item.questionId;
			let elementList = document.getElementById(parentElement);
			let checkboxes = [];
			let list = elementList.getElementsByTagName('input');

			for (let item of list) {
				checkboxes.push(item);
			}
			checkboxes.forEach((e) => {
				e.checked = false;
			});

			let QuestionId = item.questionId;
			item.response = item.response.slice(-1);
			this.form.responses[index] = {
				[QuestionId]: item.response,
			};
			// this.updateMandatoryQuestion();
		},

		conditionalHandle(item, index) {
			let questionId = item.questionId;
			let trigger = item.response.trigger;
			let nextHiddenQuestion = document.getElementById(questionId).parentElement.nextElementSibling;
			let nextVisibleQuestion = document.getElementById(trigger);
			let conditionalElements = document.querySelectorAll('.conditional__element');
			let childQuestion = null;

			if (trigger != null && !item.isConditional) {
				this.form.responses.forEach((r) => {
					let key = Object.keys(r).toString();
					let question = r[key];
					if (question.trigger === trigger) {
						nextVisibleQuestion.classList.remove('hide');
					}
					for (let ele = 0; ele < conditionalElements.length; ele++) {
						let conditionalChild = conditionalElements[ele];

						if (
							conditionalChild.querySelector('[id*=' + CSS.escape(questionId) + ']') &&
							conditionalChild.id !== trigger
						) {
							if (
								conditionalChild.classList.contains('radiolist__element') ||
								conditionalChild.classList.contains('checkboxlist__element')
							) {
								let optionsSelected = conditionalChild.getElementsByTagName('input');
								let inputs = [];
								for (let item of optionsSelected) {
									inputs.push(item);
								}
								conditionalChild.classList.add('hide');
								inputs.forEach((e) => {
									e.checked = false;
								});
							}
							if (conditionalChild.classList.contains('dropdown__element')) {
								let optionSelected = conditionalChild.getElementsByTagName('select');
								optionSelected.selectedIndex = '-1';
								conditionalChild.classList.add('hide');
							}

							this.resetConditionalQuestion(conditionalChild.id);
							for (var i = 0; i < this.form.responses.length; i++) {
								let item = this.form.responses[i];
								let key = Object.keys(item).toString();

								if (key === conditionalChild.id) {
									this.form.responses[i] = {
										[conditionalChild.id]: [],
									};
								}
							}
						}
					}
				});
			} else if (trigger == null) {
				item.optionGroups.forEach((opt) => {
					if (opt.conditionalTrigger != null) {
						childQuestion = opt.conditionalTrigger;
					}
				});
				if (childQuestion != null) {
					let child = nextHiddenQuestion.querySelector('[id*=' + CSS.escape(questionId) + ']');
					let selectedOption = document.getElementById('select-' + childQuestion);
					let radioOption = document.getElementsByName('radio-' + childQuestion)[0];

					child.classList.add('hide');
					this.resetConditionalQuestion(childQuestion);
					if (child.classList.contains('dropdown__element')) {
						selectedOption.selectedIndex = '-1';
					}
					if (child.classList.contains('radiolist__element')) {
						radioOption.checked = false;
					}

					for (var i = 0; i < this.form.responses.length; i++) {
						let item = this.form.responses[i];
						let key = Object.keys(item).toString();

						if (key === childQuestion) {
							this.form.responses[i] = {
								[childQuestion]: [],
							};
						}
					}
				}
			}
			this.form.responses[index] = {
				[questionId]: item.response,
			};
		},

		radioChange(item, index) {
			let questionId = item.questionId;
			let hasConditional = false;

			this.form.responses[index] = {
				[questionId]: item.response,
			};
			item.optionGroups.forEach((opt) => {
				if (opt.conditionalTrigger != null) {
					hasConditional = true;
				}
			});
			if (hasConditional) {
				this.conditionalHandle(item, index);
			}
		},

		radioUnselect(item, index) {
			let questionId = item.questionId;
			let response = item.isMandatory ? item.response : [];

			this.form.responses[index] = {
				[questionId]: response,
			};

			return response;
		},

		selectChange(item, index) {
			let questionId = item.questionId;
			let hasConditional = false;
			this.form.responses[index] = {
				[questionId]: item.response,
			};
			item.optionGroups.forEach((opt) => {
				if (opt.conditionalTrigger != null) {
					hasConditional = true;
				}
			});
			if (hasConditional) {
				this.conditionalHandle(item, index);
			}
		},

		goToErrorField(field, type) {
			let errorField = document.getElementById(field);
			let firstInput = errorField.querySelector('[id^=' + CSS.escape(field) + ']');
			let selectInput = errorField.querySelector('[id*=' + CSS.escape(field) + ']');

			errorField.scrollIntoView({ behavior: 'smooth' }, true);
			if (type === 'CheckboxList' || type === 'RadioList') {
				firstInput.focus();
			} else if (type === 'Dropdown') {
				selectInput.focus();
			}
		},

		checkMandatoryQuestions() {
			let mandatoryQuestions = [];
			this.mandatoryQuestions = [];

			this.stepQuestions.forEach((i) => {
				if (i.isMandatory && i.response.length === 0) {
					mandatoryQuestions.push({
						id: i.questionId,
						label: i.label,
						type: i.type,
					});
				} else if (i.isMandatory) {
					let questionField = document.getElementById(i.questionId);
					let fieldMessage = document.getElementById(i.label + '-field-error__message');
					questionField.classList.remove('govuk-form-group--error');
					fieldMessage.classList.add('hide');
				}
			});

			if (mandatoryQuestions.length > 0) {
				for (let i in mandatoryQuestions) {
					let item = mandatoryQuestions[i];
					let questionErrorField = document.getElementById(item.id);
					let fieldErrorMessage = document.getElementById(item.label + '-field-error__message');

					this.mandatoryQuestions.push({
						id: item.id,
						label: item.label,
						type: item.type,
					});
					questionErrorField.classList.add('govuk-form-group--error');
					fieldErrorMessage.classList.remove('hide');
				}
			}

			this.checkValidDateQuestions();

			if (this.mandatoryQuestions.length == 0) {
				this.formIsValid = true;
				mandatoryQuestions = [];
				this.mandatoryQuestions = [];
			}
		},

		checkValidDateQuestions() {
			const filteredData = this.stepQuestions.find(item => item.type === "DateInput");

			if (!filteredData || !this.dateErrors) {
				return;
			}

			let questionErrorField = document.getElementById(filteredData.questionId);

			for (let error in this.dateErrors.errors) {
				let fieldErrorValidationMessage = document.getElementById(filteredData.label + '-field-error__date-validations-' + error);
				fieldErrorValidationMessage.classList.add('hide')
			}

			if (!this.dateErrors.isValid) {
				questionErrorField.classList.add('govuk-form-group--error')
				for (let error in this.dateErrors.errors) {
					let fieldErrorValidationMessage = document.getElementById(filteredData.label + '-field-error__date-validations-' + error);
					fieldErrorValidationMessage.classList.remove('hide')
				}

				this.mandatoryQuestions.push({
					id: filteredData.id,
					label: filteredData.label,
					type: filteredData.type,
				});
			}
		},

		parseStringToDate(dateString) {
			const [day, month, year] = dateString.split('-').map(Number);
			return new Date(year, month - 1, day);
		},

		validateForm() {
			this.checkMandatoryQuestions();
			if (this.formIsValid) {
				this.logResponse();
				if (this.confirmationDialog.isCondition && this.confirmationDialog.turnOn) {
					this.confirmationDialog.show = true
				}
				else {
					this.$router.push({ name: this.nextUrl.toLowerCase() });
				}
			}
		},

		scrollToTopOfQAB() {
			document.getElementById('app').scrollIntoView({ behavior: 'smooth' }, true);
		},

		submitFormTest() {
			this.validateForm();
			this.scrollToTopOfQAB();
		},

		submitForm(form, valid) {
			valid.$touch();
			if (valid.$pending || valid.$error) {
				setTimeout(() => {
					document.querySelector('.error-summary__link').focus();
				}, 0);
				return;
			}
			if (document.getElementById('testMode').checked === true) {
				this.submitTestForm();
				this.$router.push({ name: 'summary' });
			} else {
				this.logResponse();
				this.$router.push({ name: this.form.url.toLowerCase() });
			}
		},

		submitFormQuestion() {
			this.submitFormTest();
		},

		loadQuestion() {
			let json = dataService.getQuestions();
			let steps = json.steps;
			let currentStep = dataService.findElement(steps, dataService.parseUrl());
			let stepPosition = steps.indexOf(currentStep);
			if (stepPosition === 0) {
				this.firstStep = true;
			}

			this.id = currentStep.id;
			this.stepData = currentStep;
			this.stepType = currentStep.type;
			this.sectionId = currentStep.sectionId;
			this.stepTitle = currentStep.title;
			this.stepTitleVisible = currentStep.titleVisible;
			this.stepDescription = currentStep.description;
			this.name = currentStep.responseId;
			this.stepQuestions = currentStep.questions;
			this.errorMessage = currentStep.errorMessage;
			this.defaultDestination = currentStep.defaultDestination;
			this.confirmationDialog.turnOn = currentStep.confirmationDialogOn;

			for (let q in currentStep.questions) {
				let qa = currentStep.questions[q];
				let id = qa.questionId;

				this.form.responses[q] = {
					[id]: qa.response,
				};
			}

			this.scrollToTopOfQAB();
		},

		loadAnswer() {
			let previousResponse = dataService.wasAnsweredBefore(this.id);

			if (previousResponse != null) {
				let preR = previousResponse.responses;
				for (var i in preR) {
					let item = preR[i];
					let itemID = item.responseId;
					if (item.isConditional) {
						let el = document.getElementById(itemID);
						el.classList.remove('hide');
					}

					if (item.questionType === 'TextInput') {
						let el = document.getElementById(`textinput-${item.responseId}`);
						let event = new Event('change');
						el.dispatchEvent(event);
					}

					if (item.questionType === 'DateInput') {
						this.date = this.convertInputStringToDateofBirth(item.response[0].response, '-');
					}
				}
			}

			let textAreaQuestion = this.stepQuestions.filter(qa => qa.type === "TextArea");

			for (let question of textAreaQuestion) {

				let elTextArea = document.getElementById(`textarea-${question.questionId}`);

				if (elTextArea) {
					let event = new Event('change');
					elTextArea.dispatchEvent(event);
				}
			}
		},

		convertInputStringToDateofBirth(dateString, splitMark) {
			if (dateString !== "") {
				const dateArray = dateString.split(splitMark);
				this.dateOfBirth = {
					day: dateArray[0].trim(),
					month: dateArray[1].trim(),
					year: dateArray[2].trim(),
				}

				return new Date(dateArray[2].trim(), dateArray[1].trim() - 1, dateArray[0].trim());
			}
		},

		isResponseExist(responses) {
			return !responses.find(s => s.response.length === 0);
		},

		logResponse() {
			let stepScore = [];
			let response = {
				id: this.id,
				responseId: this.name,
				sectionId: this.sectionId - 1,
				totalScore: [],
				responses: [],
			};

			for (var selection in this.form.responses) {
				let item = this.form.responses[selection];
				let key = Object.keys(item).toString();
				let question = item[key];
				let questionType = null;
				let questionResponse = [];
				let questionConditional = null;

				// Check question type is a checkbox list
				if (Array.isArray(question)) {
					let res = [];
					for (var i in question) {
						let inneritem = question[i];
						questionType = inneritem.questionType;
						questionConditional = inneritem.isConditional;
						questionResponse.push({
							id: inneritem.id,
							questionType: questionType,
							response: inneritem.response,
							isConditional: questionConditional,
							score: inneritem.score,
							trigger: inneritem.trigger,
						});
						res.push(inneritem.score);
						stepScore.push(inneritem.score);
					}
					// Else question type is a radio list OR dropdown select
				} else {
					questionType = question.questionType;
					questionConditional = question.isConditional;
					questionResponse.push({
						id: question.id,
						questionType: questionType,
						response: question.response,
						isConditional: questionConditional,
						score: question.score,
						trigger: question.trigger,
					});

					stepScore.push(question.score);
				}
				let r = {
					responseId: key,
					questionType: questionType,
					response: questionResponse,
					isConditional: questionConditional,
					parentTriggered: false,
				};
				response.responses.push(r);
			}

			this.setModalShowCondition(response.responses);

			response.totalScore = stepScore.reduce((a, b) => a + b, 0);

			this.stepData.responses.forEach((e) => {
				let from = e.range[0];
				let to = e.range[1];

				if (response.totalScore >= from && response.totalScore <= to) {
					this.nextUrl = e.path;
				} else if (stepScore.length === 0) {
					this.nextUrl = this.defaultDestination;
				}
			});

			dataService.logResponse(response);
		},

		setModalShowCondition(responses) {
			if (!this.isResponseExist(responses)) {
				this.confirmationDialog.isCondition = true;
			} else {
				this.confirmationDialog.isCondition = false;
			}
		},

		redirectToTimeout() {
			dataService.resetResponses();
			this.$router.push({ name: 'timeout' });
		},
	},
};
</script>
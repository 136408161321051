import Vue from 'vue';
// import Vuex from 'vuex'
import App from './App.vue'
import router from "./router/";
import components from "./components";

Vue.config.productionTip = false;
Vue.config.filenameHashing = false;

// const store = new Vuex.Store({
// 	state: {
// 		summaryAvailable: false
// 	},
// 	mutations: {
// 		isSummaryAvailable(state, payload){
// 			state.summaryAvailable = payload;
// 		}
// 	}, 
// 	getters: {
// 		summaryAvailable(state) {
// 			return state.summaryAvailable;
// 		}
// 	}
// });

new Vue({
	router,
	components,
	// store,
	render: h => h(App),
}).$mount('#app')

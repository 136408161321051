import * as axios from 'axios';
import { API } from './config';
// import steps from '../../public/api/cms_data.json';

let steps = window.qabpData;
let responses = [];

//Retrieve a list of questions from a locally hosted json file.
const getData = async function () {
    try {
        const response = await axios.get(`${API}/data.json`);
        let data = parseList(response);
        const questions = data;
        return questions;
    } catch (error) {
        console.error(error);
        return [];
    }
};

const getQuestions = function () {
    return steps;
}

const resetResponses = function () {
    responses = [];
}

const resetResponsesExpectFromSteps = function (steps) {
    let newResponses = []

    for (let step of steps) {
        let response = responses.find(f => f.responseId === step)

        if (response) {
            newResponses.unshift(response)
        }
    }

    responses = newResponses;
}

//Ensure that the data we are returning can be accessed and also contains an object data type
const parseList = response => {
    if (response.status != 200) throw Error(response.message);
    if (!response.data) return [];
    let list = response.data;
    if (typeof list != 'object') {
        list = [];
    }
    return list;
};

//Parse the current URL to ensure we retriece the correct question from the data
const parseUrl = function () {
    var splitUrl = window.location.href.split("/");
    return splitUrl[splitUrl.length - 1].trim("#");
};

//retrieve an element from our array based on the id matching the supplied propValue
const findElement = function (arr, propValue) {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i]["responseId"] == decodeURIComponent(propValue)) {
            return arr[i];
        }
    }
    //return "";
};

const findElementByResponseId = function (arr, responseId) {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i]["responseId"] === responseId) {
            return arr[i];
        }
    }
    //return "";
};

const findResponseElement = function (arr, propValue) {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i]["id"] == propValue) {
            return arr[i];
        }
    }
    //return "";
};

const findIndex = function (arr, propValue) {
    for (var i = 0; i < arr.length; i++)
        if (arr[i]["id"] == propValue) return i;
}

//log a users response to a question when the move to the next question
const logResponse = function (response) {
    let itemExist = false;
    let rIdx = null;
    // let scoreChanged = false;  QAB
    for (var i in responses) {
        let item = responses[i];
        if (item.id === response.id) {
            itemExist = true;
            rIdx = responses.indexOf(item);

            // QAB
            // if (item.totalScore != response.totalScore) { 
            //     scoreChanged = true;
            // }
        }
    }

    // SEDS
    if (itemExist) {
        if (rIdx > -1) {
            responses.splice(rIdx, 1);
        }
    }

    // QAB
    // if (itemExist) {
    //     if (rIdx > -1) {
    //         if (scoreChanged) {
    //             responses.splice(rIdx);
    //         } else {
    //             responses.splice(rIdx, 1);
    //         }
    //     }
    // }

    let previousResponse = findElement(responses, response.id);
    if (previousResponse != undefined) {
        if (previousResponse.responses) {
            if (isEqual(previousResponse.responses, response.responses)) {
                return responses.length;
            } else {
                let removeIndex = responses.indexOf(previousResponse);
                responses.splice(removeIndex, 1);
                responses.push(response);
            }
        } else {
            let removeIndex = responses.indexOf(previousResponse);
            responses.splice(removeIndex, 1);
            responses.push(response);
        }
    } else {
        responses.push(response);
    }
    return responses.length;
};

const wasAnsweredBefore = function (id) {
    // return findElement(responses, id);
    return findResponseElement(responses, id);
}

//retrieve the user's responses for the results page
const getResponses = function () {
    return responses;
};

const getCompleteData = function () {
    var _dataObject = responses;
    return _dataObject;
}

const isEqual = function (a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

const removeTags = function (str) {
    if ((str === null) || (str === '')) {
        return false;
    } else {
        str = str.toString();
    }
    return str.replace(/(<([^>]+)>)/ig, '');
}

export const dataService = {
    getData,
    getQuestions,
    resetResponses,
    parseUrl,
    findElement,
    findElementByResponseId,
    findIndex,
    logResponse,
    wasAnsweredBefore,
    getResponses,
    getCompleteData,
    removeTags,
    resetResponsesExpectFromSteps,
};

import Vue from "vue";
import Vuelidate from 'vuelidate';

import QABP from './components/index';

Vue.component("icon", QABP.Icons.FontAwesomeIcon);

Vue.component("Accordion", QABP.Misc.Accordion);
Vue.component("BackLink", QABP.Misc.BackLink);
Vue.component("BackButton", QABP.Misc.BackButton)
Vue.component("Button", QABP.Misc.Button);
Vue.component("Checklist", QABP.Misc.Checklist);
Vue.component("Details", QABP.Misc.Details);
Vue.component("Panel", QABP.Misc.Panel);
Vue.component("InsetText", QABP.Misc.InsetText);
Vue.component("Wysiwyg", QABP.Misc.Wysiwyg);
Vue.component("Section", QABP.Misc.Section);
Vue.component("Form", QABP.Forms.Form);
Vue.component("IntroForm", QABP.Forms.IntroForm);
Vue.component("RadioList", QABP.Forms.RadioList);
Vue.component("Fieldset", QABP.Forms.Fieldset);
Vue.component("TextInput", QABP.Forms.TextInput);
Vue.component("Textarea", QABP.Forms.Textarea);
Vue.component("RadioButton", QABP.Forms.RadioButton);
Vue.component("CheckboxList", QABP.Forms.CheckboxList);
Vue.component("CheckboxButton", QABP.Forms.CheckboxButton);
Vue.component("Submit", QABP.Forms.Submit);
Vue.component("Dropdown", QABP.Forms.Dropdown);
Vue.component("Errors", QABP.Forms.Errors);
Vue.component("Modal", QABP.Forms.Modal);
Vue.component("Loader", QABP.Forms.Loader);
Vue.component("DateInput", QABP.Forms.DateInput);

Vue.use(Vuelidate);

export default {};
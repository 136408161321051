<template>
	<div :class="[getGroupClass, {'form-group--error' : validate.$error}]">
		<label class="label date__label" :for="id">{{label}}</label>
		<span :id="'input-ex-hint'+id" class="hint" v-if="hintText != ''">
			{{hintText}}
		</span>
		<span :id="'input-ex-error'+id" class="error-message" v-if="validate.$error">
			{{errorMessage}}
		</span>
		<input
			autocomplete="off"
			:class="[getClass, {'input--error' : validate.$error}]"
			v-model="m"
			:name="name"
			:id="id"
			:type="type">
	</div>
</template>

<script>
export default {
	name: 'TextInput',
	props: {
		id: {
			type: String,
			required: true,
			default: null
		},
		name: {
			type: String,
			required: true,
			default: null
		},
		label: {
			type: String,
			required: true,
			default: null
		},
		value: {
			type: String,
			default: ""
		},
		type: {
			type: String,
			default: "text"
		},
		validate: {
			type: Object,
			required: false
		},
		hintText: {
			type: String,
			default: null
		},
		errorMessage: {
			type: String,
			default: null
		},
	},
	data() {
		return {
			getGroupClass: "form-group",
			getClass: "input",
		}
	},
	computed: {
		m: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit("input", value);
			}
		}
	}
}
</script>